import { useState, useEffect } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function useUserInquiries() {
  const [inquiries, setInquiries] = useState();

  useEffect(() => {
    const getInquiries = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await fetch(apiUrl + `/inquiries/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const json = await response.json();

        if (!response.ok) {
          return;
        }

        setInquiries(json);
      } catch (error) {
        return;
      }
    };

    getInquiries();
  }, []);

  return inquiries;
}

export default useUserInquiries;
