import React, { useState, useEffect, useCallback } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  saveDatesUnavailable,
  removeDatesUnavailable,
  removeDateUnavailable,
} from "../House/SubmitHandler.js";

const localizer = momentLocalizer(moment);

function BigCalendar(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const lastUsedDate = location.state?.dates || {};
  const [date, setDate] = useState(
    lastUsedDate.length > 0 ? new Date(lastUsedDate[0]) : new Date()
  );
  const [myEvents, setEvents] = useState([]);
  const [isCleared, setIsCleared] = useState(false);

  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);

  useEffect(() => {
    const unavailableEvents = props.datesUnavailable.map((date) => ({
      start: moment(date, "MM/DD/YYYY"),
      end: moment(date, "MM/DD/YYYY"),
      title: "Unavailable",
    }));
    setEvents(unavailableEvents);
  }, [props.datesUnavailable]);

  useEffect(() => {
    if (isCleared) {
      navigate("/admin/bookings", {
        state: {},
      });
      setIsCleared(false);
    }
  }, [isCleared, navigate]);

  useEffect(() => {
    setIsCleared(true);
  }, [date]);

  const handleSelectSlot = ({ start, end }) => {
    // Adjust the end date to be one day before the selected end date
    const adjustedEnd = moment(end).subtract(1, "day").toDate();

    const formattedStart = moment(start).format("MM/DD/YYYY");
    const formattedEnd = moment(adjustedEnd).format("MM/DD/YYYY");

    // Check if any dates in the selected range are already unavailable
    const overlappingDates = props.datesUnavailable.filter((date) =>
      moment(date, "MM/DD/YYYY").isBetween(start, adjustedEnd, null, "[]")
    );

    if (overlappingDates.length > 0) {
      const confirmed = window.confirm(
        `Some of the selected dates are already unavailable. Do you want to remove them instead?`
      );
      if (confirmed) {
        removeDatesUnavailable(overlappingDates, () => {
          navigate("/admin/bookings", {
            state: { dates: overlappingDates },
          });
          window.location.reload();
        });
      }
    } else {
      const confirmed = window.confirm(
        `Are you sure you want to make ${formattedStart} - ${formattedEnd} unavailable?`
      );
      if (confirmed) {
        saveDatesUnavailable(start, adjustedEnd, () => {
          navigate("/admin/bookings", {
            state: { dates: [formattedStart] },
          });
          window.location.reload();
        });
      }
    }
  };

  const handleSelectEvent = (event) => {
    const confirmed = window.confirm(
      `Are you sure you want to make the date "${event.start.format(
        "MM/DD/YYYY"
      )}" available?`
    );
    if (confirmed) {
      removeDateUnavailable(event.start.format("MM/DD/YYYY"), () => {
        navigate("/admin/bookings", {
          state: { dates: [event.start.format("MM/DD/YYYY")] },
        });
        window.location.reload();
      });
    }
  };

  const handleYearChange = (year) => {
    const newDate = new Date(year, date.getMonth(), date.getDate());
    setDate(newDate);
  };

  const handleMonthChange = (month) => {
    const newDate = new Date(date.getFullYear(), month, date.getDate());
    setDate(newDate);
  };

  return (
    <div>
      <b>*Click and drag dates to make multiple days unavailable*</b>
      <br />
      <br />
      <b>Go to Month: </b>
      <select
        defaultValue={date.getMonth()}
        onChange={(e) => handleMonthChange(e.target.value)}
      >
        {Array.from({ length: 12 }, (_, index) => {
          return (
            <option key={index} value={index}>
              {new Date(0, index).toLocaleDateString(undefined, {
                month: "long",
              })}
            </option>
          );
        })}
      </select>
      <b> Year: </b>
      <select
        defaultValue={new Date().getFullYear()}
        onChange={(e) => handleYearChange(e.target.value)}
      >
        {Array.from({ length: 7 }, (_, index) => {
          const year = new Date().getFullYear() - 3 + index;
          return (
            <option key={year} value={year}>
              {year}
            </option>
          );
        })}
      </select>

      <br />
      <br />
      <Calendar
        localizer={localizer}
        date={date}
        defaultView={Views.MONTH}
        events={myEvents}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        onNavigate={onNavigate}
        views={["month"]} // Only allow the month view
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
}

export default BigCalendar;
