// Import React
import React from "react";

// Import other components
import Navigation from "./components/Admin/Navigation";
import House from "./components/Admin/House/House";
import Bookings from "./components/Admin/Bookings/Bookings";
import Preview from "./components/Admin/Preview";
import Profile from "./components/User/Profile";
import Reviews from "./components/Admin/Reviews/Reviews";
import Inquiries from "./components/Admin/Inquiries";
import Rates from "./components/Admin/Rates/Rates";

// Import hooks
import { useAuth } from "./components/Hooks/AuthContext";
import useHome from "./components/Hooks/useHome";
import useSlideshowImages from "./components/Hooks/useSlideshowImages";
import useOwnerImage from "./components/Hooks/useOwnerImage";
import useDatesUnavailable from "./components/Hooks/useDatesUnavailable";
import useReviews from "./components/Hooks/useReviews";
import usePendingReviews from "./components/Hooks/usePendingReviews";
import useAllInquiries from "./components/Hooks/useAllInquiries";
import useRates from "./components/Hooks/useRates";
import useFees from "./components/Hooks/useFees";

function AppAdmin(props) {
  const { user } = useAuth();
  const home = useHome();
  const slideshowImages = useSlideshowImages();
  const ownerImage = useOwnerImage();
  const datesUnavailable = useDatesUnavailable();
  const fees = useFees();
  const reviews = useReviews();
  const pendingReviews = usePendingReviews();
  const inquiries = useAllInquiries();
  const rates = useRates();

  return (
    <div style={{ marginRight: "2%", marginLeft: "2%" }}>
      {home && <Navigation title={home.title} />}
      {props.page === "house" && home && (
        <House
          home={home}
          slideshowImages={slideshowImages}
          ownerImage={ownerImage}
        />
      )}
      {props.page === "profile" && user && <Profile user={user} />}
      {props.page === "reviews" && reviews && pendingReviews && home && (
        <Reviews
          reviews={reviews}
          pendingReviews={pendingReviews}
          home={home}
        />
      )}
      {props.page === "inquiries" && inquiries && (
        <Inquiries inquiries={inquiries} />
      )}
      {props.page === "bookings" && home && datesUnavailable && fees && (
        <Bookings home={home} datesUnavailable={datesUnavailable} fees={fees} />
      )}
      {props.page === "rates" && rates && fees && (
        <Rates rates={rates} fees={fees} />
      )}
      {props.page === "preview" && home && slideshowImages && ownerImage && (
        <Preview slideshowImages={slideshowImages} ownerImage={ownerImage} />
      )}
    </div>
  );
}

export default AppAdmin;
