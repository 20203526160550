import React, { useRef, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function DateOfStaySelect(props) {
  const inputRef = useRef(null);
  const [value, setValue] = useState(
    props.dateOfStay ? props.dateOfStay : "-1"
  );

  const generateMonthsAndYears = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const options = [];
    const currentDate = new Date();
    const totalMonths = 1 * 12; // 1 years in months

    options.push(
      <MenuItem key={-1} value="-1" disabled>
        Select one
      </MenuItem>
    );

    for (let i = 0; i < totalMonths; i++) {
      const date = new Date(currentDate);
      date.setMonth(currentDate.getMonth() - i);

      const month = months[date.getMonth()];
      const year = date.getFullYear();

      const label = `${month} ${year}`;

      options.push(
        <MenuItem key={i} value={label}>
          {label}
        </MenuItem>
      );
    }

    return options;
  };

  const handleSelectChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    inputRef.current.value = inputValue;

    if (props.onChange) {
      props.onChange(inputValue);
    }
  };

  const isValueValid = value === props.startValue || props.firstReview;

  return (
    <div>
      <Select
        id="dateOfStay"
        onChange={handleSelectChange}
        inputRef={inputRef}
        value={value}
        MenuProps={{ style: { maxHeight: "400px" } }}
        sx={{ color: isValueValid ? "inherit" : "#c42525" }}
      >
        {generateMonthsAndYears()}
      </Select>
    </div>
  );
}

export default DateOfStaySelect;
