import React, { useRef, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const InputTextPassword = (props) => {
  const inputRef = useRef(null);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setIsValueChanged(false);
    setInputValue(props.defaultValue || "");
  }, [props.defaultValue]);

  useEffect(() => {
    setInputValue(""); // Reset input value on mount
  }, []); // Empty dependency array means this effect runs once on mount

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setIsValueChanged(newValue !== props.defaultValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <TextField
        id={props.id}
        label={props.label}
        autoComplete="new-password"
        variant="filled"
        InputLabelProps={{ shrink: true }}
        value={inputValue}
        inputRef={inputRef}
        type={showPassword ? "text" : "password"}
        onChange={handleInputChange}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: "#f0f0f0",
            padding: "8px 16px",
            fontSize: "1.4em",
            color: isValueChanged ? "#c42525" : "black",
          },
          "& .MuiInputLabel-root": {
            color: "#999",
            fontWeight: "bold",
            fontSize: "1.4em",
          },
          "& .MuiInput-underline:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleTogglePasswordVisibility}
                edge="end"
                aria-label="toggle password visibility"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default InputTextPassword;
