import React from "react";
import "./styles.css";

function AboutTheOwner(props) {
  return (
    <div>
      <div className="header-large">About The Owner</div>
      <hr></hr>
      <div className="container-owner">
        <img src={props.ownerImage.path} className="image-owner" alt="owner" />
        <div className="content-owner">
          <div className="header-text-bold">{props.owner.displayName}</div>
          <span className="main-text-bold" style={{ margin: "1%" }}>
            {props.owner.note}
          </span>
          <div style={{ margin: "1%" }}>
            <span className="main-text-bold">Languages Spoken: </span>
            <span>{props.owner.languagesSpoken}</span>
          </div>
          <div style={{ margin: "1%" }}>
            <span className="main-text-bold">Listed Since: </span>
            <span>{props.owner.listedSince}</span>
          </div>
          <div style={{ margin: "1%" }}>
            <span className="main-text-bold">Response Time: </span>
            <span>{props.owner.responseTime}</span>
          </div>
          <div style={{ margin: "1%" }}>
            <span className="main-text-bold">Interactions With Guests: </span>
            <span>{props.owner.interaction}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutTheOwner;
