import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    loading: true,
    admin: false,
  });

  const checkAuthStatus = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");

      const updateAuthState = (newAuthState) => {
        setAuthState((prevState) => ({
          ...prevState,
          ...newAuthState,
          loading: false,
        }));
      };

      if (token) {
        const response = await fetch(apiUrl + "/auth/check-auth-status", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const result = await response.json();
          const { user } = result;
          updateAuthState({
            admin: result.user.role === "admin",
            isAuthenticated: true,
            user,
          });
          return {
            admin: result.user.role === "admin",
            isAuthenticated: true,
            user,
          };
        } else {
          updateAuthState({ isAuthenticated: false, user: null, admin: false });
          return { isAuthenticated: false };
        }
      } else {
        updateAuthState({ isAuthenticated: false, user: null, admin: false });
        return { isAuthenticated: false };
      }
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await checkAuthStatus();
      } catch (error) {
        console.error("Error setting authentication state:", error);
        setAuthState({
          isAuthenticated: false,
          user: null,
          loading: false,
          admin: false,
        });
      }
    };

    fetchData();
  }, [checkAuthStatus]);

  const login = async (credentials) => {
    try {
      const response = await fetch(apiUrl + "/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        setAuthState({
          isAuthenticated: false,
          user: null,
          loading: false,
          admin: false,
        });
        return;
      }

      const result = await response.json();

      if (!response.ok) {
        setAuthState({
          isAuthenticated: false,
          user: null,
          loading: false,
          admin: false,
        });
        return;
      }

      const token = result.token;
      localStorage.setItem("token", token);
      setAuthState({
        admin: result.user.role === "admin",
        isAuthenticated: true,
        user: result.user,
        loading: false,
      });
    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      // Remove the token and set isAuthenticated to false
      localStorage.removeItem("token");
      setAuthState({
        isAuthenticated: false,
        user: null,
        loading: false,
        admin: false,
      });
    } catch (error) {
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{ ...authState, login, logout, checkAuthStatus }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider };
