import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { deleteInquiry, deleteInquiries } from "./House/SubmitHandler.js";

function Inquiries(props) {
  //
  // Delete Icon Stuff
  //
  const [selectedInquiryToDeleteID, setSelectedInquiryToDeleteID] = useState(
    []
  );
  const [selectedInquiryToDeleteEmail, setSelectedInquiryToDeleteEmail] =
    useState([]);
  const [
    showDeleteSingleConfirmationModal,
    setShowDeleteSingleConfirmationModal,
  ] = useState(false);

  // When the user clicks the delete icon (trash can)
  const handleDeleteSingleClick = (id, email) => {
    setSelectedInquiryToDeleteID(id);
    setSelectedInquiryToDeleteEmail(email);
    setShowDeleteSingleConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleDeleteSingleCancel = () => {
    setShowDeleteSingleConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleDeleteSingleConfirm = () => {
    deleteInquiry(selectedInquiryToDeleteID);
    setShowDeleteSingleConfirmationModal(false);
  };

  //
  // Delete Selected
  //
  const [checkAll, setCheckAll] = useState(false);
  const [selectedInquiriesToDelete, setSelectedInquiriesToDelete] = useState(
    []
  );
  const [selectedInquiriesToDeleteEmail, setSelectedInquiriesToDeleteEmail] =
    useState([]);
  const [
    showDeleteSelectedConfirmationModal,
    setShowDeleteSelectedConfirmationModal,
  ] = useState(false);

  // When the top checkbox is clicked
  const handleCheckAll = (event) => {
    setCheckAll(event.target.checked);
    const checkboxes = document.querySelectorAll(
      'tbody input[type="checkbox"][name="inquiriesCheckbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
    });

    setSelectedInquiriesToDelete(
      Array.from(
        document.querySelectorAll(
          'tbody input[type="checkbox"][name="inquiriesCheckbox"]:checked'
        )
      ).map((checkbox) => checkbox.id)
    );

    setSelectedInquiriesToDeleteEmail(
      Array.from(
        document.querySelectorAll(
          'tbody input[type="checkbox"][name="inquiriesCheckbox"]:checked'
        )
      ).map((checkbox) => checkbox.value)
    );
  };

  // When a checkbox is clicked
  const handleCheckbox = (event) => {
    const checkboxes = document.querySelectorAll(
      'tbody input[type="checkbox"][name="inquiriesCheckbox"]'
    );
    const checkedCount = document.querySelectorAll(
      'tbody input[type="checkbox"][name="inquiriesCheckbox"]:checked'
    ).length;
    setCheckAll(checkedCount === checkboxes.length);

    setSelectedInquiriesToDelete(
      Array.from(
        document.querySelectorAll(
          'tbody input[type="checkbox"][name="inquiriesCheckbox"]:checked'
        )
      ).map((checkbox) => checkbox.id)
    );

    setSelectedInquiriesToDeleteEmail(
      Array.from(
        document.querySelectorAll(
          'tbody input[type="checkbox"][name="inquiriesCheckbox"]:checked'
        )
      ).map((checkbox) => checkbox.value)
    );
  };

  // When the user clicks delete selected inquiries
  const handleDeleteSelectedClick = () => {
    setShowDeleteSelectedConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleDeleteSelectedCancel = () => {
    setShowDeleteSelectedConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleDeleteSelectedConfirm = () => {
    deleteInquiries(selectedInquiriesToDelete);
    setShowDeleteSelectedConfirmationModal(false);
  };

  function formatDate(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  // Inquiries Table
  const rows = props.inquiries.map((row, index) => {
    return (
      <tr key={row._id} className={index % 2 === 0 ? "even-row" : "odd-row"}>
        <td>
          <input
            type="checkbox"
            name="inquiriesCheckbox"
            id={row._id}
            value={row.email}
            onChange={handleCheckbox}
          />
        </td>
        <td>{formatDate(row.dateCreated)}</td>
        <td>{row.email}</td>
        <td>{row.firstName}</td>
        <td>{row.lastName}</td>
        <td>{row.phoneNumber}</td>
        <td>{formatDate(row.startDate)}</td>
        <td>{formatDate(row.endDate)}</td>
        <td>{row.numDays}</td>
        <td>{row.numGuests}</td>
        <td>
          $
          {Number(row.total).toLocaleString("en-US", {
            maximumFractionDigits: 0,
          })}
        </td>
        <td>
          $
          {Number(row.totalTaxAndFees).toLocaleString("en-US", {
            maximumFractionDigits: 0,
          })}
        </td>
        <td>
          $
          {Number(row.cleaningFee).toLocaleString("en-US", {
            maximumFractionDigits: 0,
          })}
        </td>
        <td>
          $
          {Number(row.estimatedTotal).toFixed(0).toLocaleString("en-US", {
            maximumFractionDigits: 0,
          })}
        </td>
        <td
          style={{
            maxWidth: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.message}
        </td>
        <td>
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteSingleClick(row._id, row.email)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="header-large">Inquiries:</div>
      <div style={{ width: "95%", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteSelectedClick}
            disabled={selectedInquiriesToDelete.length === 0}
          >
            Delete Selected Inquiries
          </Button>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table className="admin-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={checkAll}
                    onChange={handleCheckAll}
                    id="checkAll"
                  />
                </th>
                <th className="header-cell">Date Created:</th>
                <th className="header-cell">Email:</th>
                <th className="header-cell">First Name:</th>
                <th className="header-cell">Last Name:</th>
                <th className="header-cell">Phone Number:</th>
                <th className="header-cell">Start Date:</th>
                <th className="header-cell">End Date:</th>
                <th className="header-cell">Days:</th>
                <th className="header-cell">Guests:</th>
                <th className="header-cell">Rate for number of nights:</th>
                <th className="header-cell">Lodging Tax + Booking Fee:</th>
                <th className="header-cell">Cleaning Fee:</th>
                <th className="header-cell">Estimated Total:</th>
                <th className="header-cell">Message:</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>

      <br />
      <br />
      <br />

      <Dialog
        open={showDeleteSingleConfirmationModal}
        onClose={handleDeleteSingleCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the inquiry from "
          {selectedInquiryToDeleteEmail}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSingleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSingleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteSelectedConfirmationModal}
        onClose={handleDeleteSelectedCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the selected inquiries?
          {selectedInquiriesToDeleteEmail.map((item, index) => (
            <div key={index}>"{item}"</div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSelectedCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSelectedConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Inquiries;
