import { useState, useEffect } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function useSingleReview() {
  const [review, setReview] = useState();

  useEffect(() => {
    const getReview = () => {
      try {
        const token = localStorage.getItem("token");

        fetch(apiUrl + "/reviews/user/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            setReview(json);
          })
          .catch((error) => {});
      } catch (error) {}
    };

    getReview();
  }, []); // Include dependencies if needed

  return review;
}

export default useSingleReview;
