import React, { useState, useEffect, useRef } from "react";

const PhoneNumberInput = (props) => {
  const inputRef = useRef(null);
  const [formattedValue, setFormattedValue] = useState("");

  useEffect(() => {
    setFormattedValue(formatPhoneNumber(props.defaultValue));
  }, [props.defaultValue]);

  const formatPhoneNumber = (value) => {
    if (value && value.length >= 3) {
      let formattedValue = `(${value.slice(0, 3)}`;

      if (value.length > 3) {
        formattedValue += `) ${value.slice(3, 6)}`;

        if (value.length > 6) {
          formattedValue += ` - ${value.slice(6)}`;
        }
      }

      return formattedValue;
    }

    return value;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters

    if (inputValue.length > 10) return;

    let formattedValue = formatPhoneNumber(inputValue);

    setFormattedValue(formattedValue);

    // Call the onChange prop if it exists, and pass the numeric value
    if (props.onChange) {
      props.onChange(inputValue);
    }

    // If user deletes anything inside the parentheses, keep the hyphen
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      const lastChar = formattedValue.charAt(formattedValue.length - 1);
      const secondLastChar = formattedValue.charAt(formattedValue.length - 2);
      if (lastChar === ")" && secondLastChar === "(") {
        setFormattedValue(formattedValue.slice(0, -2));
      } else if (formattedValue === "() ") {
        // Keep a single set of parentheses when the user deletes content inside
        setFormattedValue("(");
      }
    }
  };

  return (
    <input
      id={props.id}
      type="text"
      value={formattedValue}
      ref={inputRef}
      onChange={handleInputChange}
      className="loginTextBox"
    />
  );
};

export default PhoneNumberInput;
