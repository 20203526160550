import React, { useRef, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

const InputText = (props) => {
  const inputRef = useRef(null);
  const [isValueChanged, setIsValueChanged] = useState(false);

  useEffect(() => {
    // Check if inputRef.current is not null before accessing its value
    if (inputRef.current) {
      setIsValueChanged(inputRef.current.value !== props.defaultValue);
    }
  }, [props.defaultValue]);

  const handleInputChange = (event) => {
    inputRef.current.value = event.target.value;
    setIsValueChanged(event.target.value !== props.defaultValue);

    // Call the onChange prop if it exists
    if (props.onChange) {
      props.onChange();
    }
  };

  return (
    <div>
      <TextField
        id={props.id}
        label={props.label}
        variant="filled"
        InputLabelProps={{ shrink: true }}
        defaultValue={props.defaultValue}
        inputRef={inputRef}
        onChange={handleInputChange}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: "#f0f0f0",
            padding: "8px 16px",
            fontSize: "1.4em",
            color: isValueChanged ? "#c42525" : "black",
          },
          "& .MuiInputLabel-root": {
            color: "#999",
            fontWeight: "bold",
            fontSize: "1.4em",
          },
          "& .MuiInput-underline:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
      />
    </div>
  );
};

export default InputText;
