import React from "react";
import Confirmed from "./Confirmed";
import Unconfirmed from "./Unconfirmed";
import InputText from "../../Form/InputText";
import Button from "@mui/material/Button";
import { createJson, saveHome } from "../House/SubmitHandler.js";

function Reviews(props) {
  const handleSave = () => {
    const ownersNote = document.getElementById("ownersNote").value;

    const json = createJson(
      props.home.title,
      props.home.overviewCounts.bedrooms,
      props.home.overviewCounts.bathrooms,
      props.home.overviewCounts.guests,
      props.home.overviewCounts.minNights,
      props.home.overviewType,
      props.home.overview,
      props.home.overviewRules.pets,
      props.home.overviewRules.smoking,
      props.home.overviewRules.kids,
      props.home.overviewBathrooms,
      props.home.overviewBedrooms,
      props.home.owner.displayName,
      props.home.owner.note,
      props.home.owner.languagesSpoken,
      props.home.owner.responseTime,
      props.home.owner.listedSince,
      props.home.owner.interaction,
      props.home.amenities,
      props.home.reviews,
      props.home.map.googleMapsSRC,
      props.home.map.aboutTheNeighborhood,
      props.home.map.gettingAround,
      props.home.map.access,
      props.home.map.howToGetThere,
      props.home.map.activitiesNearby,
      props.home.cancellationPolicy,
      props.home.noteFromOwner,
      props.home.unavailableDates,
      ownersNote
    );

    saveHome(json);
  };

  return (
    <div>
      <InputText
        id="ownersNote"
        label="Note from owner"
        defaultValue={props.home.reviewOwnersNote}
      />
      <div style={{ display: "flex", justifyContent: "center", margin: "3px" }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
      <Unconfirmed unconfirmedReviews={props.pendingReviews} />
      <Confirmed confirmedReviews={props.reviews} />
    </div>
  );
}

export default Reviews;
