import React, { useState } from "react";
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { unconfirmReview } from "../Reviews/SubmitHandler.js";
import "../admin.css";

function Confirmed(props) {
  //
  // Unconfirm Icon
  //
  const [selectedReviewToUnconfirm, setSelectedReviewToUnconfirm] = useState(
    []
  );
  const [selectedReviewToUnconfirmID, setSelectedReviewToUnconfirmID] =
    useState([]);
  const [
    showUnconfirmReviewConfirmationModal,
    setShowUnconfirmReviewConfirmationModal,
  ] = useState(false);

  // When the user clicks the Unconfirm icon (trash can)
  const handleUnconfirmReviewClick = (email, id) => {
    setSelectedReviewToUnconfirm(email);
    setSelectedReviewToUnconfirmID(id);
    setShowUnconfirmReviewConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleUnconfirmReviewCancel = () => {
    setShowUnconfirmReviewConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleUnconfirmReviewConfirm = () => {
    unconfirmReview(selectedReviewToUnconfirmID);
    setShowUnconfirmReviewConfirmationModal(false);
  };

  const rows = props.confirmedReviews.map((data, index) => {
    return (
      <tr key={index}>
        <td>{data.email}</td>
        <td>{data.rating}</td>
        <td>{data.dateOfStay}</td>
        <td>{data.reviewTitle}</td>
        <td>{data.reviewBody}</td>
        <td>
          <ReplayIcon
            style={{ cursor: "pointer", title: "Unconfirm Review" }}
            onClick={() => handleUnconfirmReviewClick(data.email, data._id)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="header-large">Confirmed Reviews:</div>
      <table className="review-table">
        <thead>
          <tr>
            <th>Email:</th>
            <th>Rating:</th>
            <th>Date of Stay:</th>
            <th>Review Title:</th>
            <th>Review Body:</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      <Dialog
        open={showUnconfirmReviewConfirmationModal}
        onClose={handleUnconfirmReviewCancel}
      >
        <DialogTitle>Unconfirm Review</DialogTitle>
        <DialogContent>
          Are you sure you want to unconfirm the review left by "
          {selectedReviewToUnconfirm}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnconfirmReviewCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUnconfirmReviewConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Confirmed;
