import React from 'react';

function ReviewCount(props) {
  return (
    <div className='header-mid'>
      {props.numReviews} Reviews
    </div>
  );
}

export default ReviewCount;