// Import React
import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";

// Import other components
import Title from "./components/Home/Title";
import ReviewCount from "./components/Home/ReviewCount";
import Slideshow from "./components/Home/Slideshow";
import Overview from "./components/Home/Overview/Overview";
import AboutTheOwner from "./components/Home/AboutTheOwner";
import Amenities from "./components/Home/Amenities/Amenities";
import Map from "./components/Home/Map";
import CancellationPolicy from "./components/Home/CancellationPolicy";
import NoteFromOwner from "./components/Home/NoteFromOwner";
import Reviews from "./components/Home/Reviews";
import BookingForm from "./components/Home/BookingForm";
import LoginButton from "./components/Login/LoginButton";
import Navigation from "./components/User/Navigation";
import "./container-styles.css";

// Import hooks
import useHome from "./components/Hooks/useHome";
import useSlideshowImages from "./components/Hooks/useSlideshowImages";
import useOwnerImage from "./components/Hooks/useOwnerImage";
import useDatesUnavailable from "./components/Hooks/useDatesUnavailable";
import useFees from "./components/Hooks/useFees";
import useRates from "./components/Hooks/useRates";
import { useAuth } from "./components/Hooks/AuthContext"; // Adjust the path as needed
import useReviews from "./components/Hooks/useReviews";

function App(props) {
  const home = useHome();
  const slideshowImages = useSlideshowImages();
  const ownerImage = useOwnerImage();
  const datesUnavailable = useDatesUnavailable();
  const fees = useFees();
  const rates = useRates();
  const { isAuthenticated } = useAuth();
  const reviews = useReviews();
  const [isSticky, setSticky] = useState(false);
  const [showReturnToTop, setShowReturnToTop] = useState(false);

  const handleScroll = () => {
    const threshold = 230;
    const returnToTopThreshold = 1000;

    if (window.scrollY > threshold) {
      setSticky(true);
    } else {
      setSticky(false);
    }

    if (window.scrollY > returnToTopThreshold) {
      setShowReturnToTop(true);
    } else {
      setShowReturnToTop(false);
    }
  };

  const handleReturnToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {props.preview ? (
        ""
      ) : isAuthenticated ? (
        home && <Navigation title={home.title} />
      ) : (
        <LoginButton />
      )}
      {home && <Title title={home.title} />}
      <div className="container">
        <div className="left-column">
          {home && reviews && <ReviewCount numReviews={reviews.length} />}
          {home && slideshowImages && (
            <Slideshow slideshowImages={slideshowImages} />
          )}
          <div className={`right-column ${isSticky ? "sticky" : ""}`}>
            {home && datesUnavailable && rates && fees && (
              <BookingForm
                datesUnavailable={datesUnavailable}
                maxGuests={home.overviewCounts.guests}
                minNights={home.overviewCounts.minNights}
                fees={fees}
                rates={rates}
              />
            )}
          </div>
          {home && (
            <Overview
              overviewCounts={home.overviewCounts}
              overviewType={home.overviewType}
              overview={home.overview}
              overviewRules={home.overviewRules}
              overviewBathrooms={home.overviewBathrooms}
              overviewBedrooms={home.overviewBedrooms}
            />
          )}
          {home && ownerImage && (
            <AboutTheOwner owner={home.owner} ownerImage={ownerImage} />
          )}
          {home && <Amenities amenities={home.amenities} />}
          {home && <Map map={home.map} />}
          {home && (
            <CancellationPolicy cancellationPolicy={home.cancellationPolicy} />
          )}
          {home && <NoteFromOwner note={home.noteFromOwner} />}
          {home && reviews && home && (
            <Reviews reviews={reviews} noteFromOwner={home.reviewOwnersNote} />
          )}
        </div>
      </div>
      <div className="footer">
        {showReturnToTop && (
          <div className="return-to-top" onClick={handleReturnToTop}>
            <FaArrowUp />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
