import React, { useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const InputSelect = (props) => {
  const inputRef = useRef(null); // Ref to hold reference to input field
  const [value, setValue] = useState(props.defaultValue); // State variable to track input value

  const handleInputChange = (event) => {
    const inputValue = event.target.value; // Get input value from event
    setValue(inputValue); // Update input value in state
    inputRef.current.value = inputValue; // Update input field value directly using ref
  };

  return (
    <div>
      <FormControl fullWidth variant="filled">
        <InputLabel htmlFor={props.label}>{props.label}</InputLabel>
        <Select
          id={props.id}
          label={props.label}
          inputRef={inputRef} // Pass ref to input field
          onChange={handleInputChange} // Set onChange event handler to update input field and state
          value={value} // Use state value for select element
          sx={{
            "& .MuiSelect-root": {
              backgroundColor: value !== props.defaultValue ? "#c42525" : "#f0f0f0", // Change background color when value is changed
              padding: "8px 16px",
              fontSize: "1.4em",
              color: value !== props.defaultValue ? "#c42525" : "initial" // Change text color of selected value
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: value !== props.defaultValue ? "#c42525" : "rgba(0, 0, 0, 0.23)" // Change outline color when value is changed
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: value !== props.defaultValue ? "#c42525" : "rgba(0, 0, 0, 0.23)" // Change outline color on hover when value is changed
            },
            "& .MuiMenuItem-root": {
              color: (menuItemProps) => ( // Change menu item color based on selected value
                menuItemProps.value === value ? "#c42525" : "initial"
              )
            },
            color: value !== props.defaultValue ? "#c42525" : "initial" // Change text color of selected value in Select
          }}
          MenuProps={{
            getcontentanchorel: null, // Remove anchor element for menu
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            }
          }}
        >
          {/* Render menu items */}
          {props.menuItems.map((item) => (
            <MenuItem key={item} value={item} sx={{ // Update sx prop for MenuItem to handle selected value text color
              "&.Mui-selected": {
                color: value !== props.defaultValue ? "red" : "initial" // Change text color of selected value
              }
            }}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default InputSelect;
