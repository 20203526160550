// LoginPage.js
import React, { useState, useEffect } from "react";
import "./login-styles.css";
import { useAuth } from "../Hooks/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

function LoginPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { login, isAuthenticated, admin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirectUser = async () => {
      // Redirect to admin page only if the user is authenticated and admin is true
      let page = "";
      if (isAuthenticated) {
        if (admin) page = "/admin/house";
        else page = "/";

        const intendedDestination = location.state?.intendedDestination || page;

        if (
          intendedDestination === "/make-inquiry" &&
          location.state?.inquiryProps
        ) {
          navigate("/make-inquiry", { state: location.state });
        } else {
          navigate(intendedDestination);
        }
      }
    };

    redirectUser();
  }, [isAuthenticated, admin, navigate, location.state]);

  const handleLoginClick = async () => {
    const email = document.getElementById("fldEmail").value;
    const password = document.getElementById("fldPassword").value;

    // Validation for email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);

    // Validation for password requirements
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isPasswordValid = passwordRegex.test(password);

    if (!isEmailValid || !isPasswordValid) {
      setErrorMessage("Invalid email or password format");
      return;
    }

    const credentials = {
      email: email,
      password: password,
    };

    try {
      setLoading(true);

      // Call the login function from the context
      await login(credentials);
    } catch (error) {
      setErrorMessage("Error logging in. Please try again."); // Generic error message
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAccountClick = () => {
    navigate("/signup");
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="loginContainer">
      <HomeIcon
        style={{ cursor: "pointer", width: "35px", height: "35px" }}
        onClick={handleGoHome}
      />
      <h1 className="loginHeader">Log In</h1>
      <div style={{ display: errorMessage ? "block" : "none", color: "red" }}>
        {errorMessage}
        <br />
        <br />
      </div>
      <label htmlFor="fldEmail">Email</label>
      <br />
      <input type="text" id="fldEmail" className="loginTextBox" />
      <br />
      <label htmlFor="fldPassword">Password</label>
      <br />
      <input type="password" id="fldPassword" className="loginTextBox" />
      <br />
      <button
        className="login-button"
        style={{ marginTop: "5px", marginBottom: "5px" }}
        onClick={handleLoginClick}
        disabled={loading}
      >
        {loading ? <span className="loading-spinner"></span> : "Log In"}
      </button>

      <div className="orContainer">
        <hr className="orHr" />
        <span className="orText">OR</span>
        <hr className="orHr" />
      </div>

      <button
        className="createAccountButton"
        onClick={handleCreateAccountClick}
      >
        Create Account
      </button>
    </div>
  );
}

export default LoginPage;
