import React, { useState } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

const fullStar = apiUrl + "/images/icons/star_full.png";
const emptyStar = apiUrl + "/images/icons/star_empty.png";

const StarRating = (props) => {
  const [rating, setRating] = useState(props.rating);

  const changeRating = (newRating) => {
    setRating(newRating);
    props.onChange(newRating);
  };

  return (
    <div>
      {[1, 2, 3, 4, 5].map((starNumber) => (
        <img
          key={starNumber}
          src={starNumber <= rating ? fullStar : emptyStar}
          alt={`Star ${starNumber}`}
          onClick={() => changeRating(starNumber)}
          style={{
            cursor: "pointer",
            width: "24px",
            height: "24px",
            filter:
              rating >= starNumber &&
              props.startValue !== rating &&
              !props.firstReview
                ? "brightness(0) saturate(100%) invert(22%) sepia(99%) saturate(5072%) hue-rotate(342deg) brightness(85%) contrast(96%)"
                : "brightness(1)",
          }}
        />
      ))}
    </div>
  );
};

export default StarRating;
