import React from "react";
import Button from "@mui/material/Button";

const SaveButton = ({ noStyle, onClick }) => {
  return (
    <div
      style={
        noStyle
          ? {}
          : {
              position: "fixed",
              bottom: 25,
              left: "50%", // Set to the middle of the screen horizontally
              transform: "translateX(-50%)", // Center the element
              zIndex: 100,
            }
      }
    >
      <Button variant="contained" color="primary" onClick={onClick}>
        Save
      </Button>
    </div>
  );
};

export default SaveButton;
