import React, { useState } from "react";
import "../user-styles.css";
import DateOfStaySelect from "../../Form/DateOfStaySelect";
import InputText from "./InputText";
import Button from "@mui/material/Button";
import StarRating from "./StarRating";
import { editReview, saveReview } from "./ReviewsHandler";
import Tooltip from "@mui/material/Tooltip";

function WriteReview(props) {
  const [rating, setRating] = useState(props.review.rating);
  const [dateOfStay, setDateOfStay] = useState(props.review.dateOfStay);
  const [reviewTitle, setReviewTitle] = useState(props.review.reviewTitle);
  const [reviewBody, setReviewBody] = useState(props.review.reviewBody);
  const [errorMessage, setErrorMessage] = useState("");

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleDateOfStayChange = (newDate) => {
    setDateOfStay(newDate);
  };

  const handleReviewTitleChange = (newTitle) => {
    setReviewTitle(newTitle);
  };

  const handleReviewBodyChange = (newBody) => {
    setReviewBody(newBody);
  };

  const submit = async () => {
    setErrorMessage("");

    const updatedData = {
      email: props.user.email,
      rating: rating,
      dateOfStay: dateOfStay,
      reviewTitle: reviewTitle,
      reviewBody: reviewBody,
    };

    if (dateOfStay.length === 0) {
      setErrorMessage("Error: Please select a date of stay");
      return;
    }
    if (reviewTitle.length > 120) {
      setErrorMessage("Error: Review title must be 120 characters or less");
      return;
    }
    if (reviewBody.length > 2000) {
      setErrorMessage("Error: Review body must be 2000 characters or less");
      return;
    }

    try {
      if (!props.firstReview) {
        const json = {
          reviewID: props.review._id,
          updatedData: updatedData,
        };
        await editReview(json);
      } else {
        await saveReview(updatedData);
      }
    } catch (error) {
      console.error("Error saving review:", error.message);
    }
  };

  const renderStatus = () => {
    if (props.status === "pending") {
      return (
        <Tooltip
          title={
            <span className="tooltip-content">
              Reviews will not show up on the property unless confirmed by the
              owner.
            </span>
          }
          arrow
        >
          <div className="unconfirmed-status">Unconfirmed</div>
        </Tooltip>
      );
    } else if (props.status === "notPending") {
      return (
        <Tooltip
          title={
            <span className="tooltip-content">
              Reviews will not show up on the property unless confirmed by the
              owner.
            </span>
          }
          arrow
        >
          <div className="unconfirmed-status">Confirmed</div>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <div>
      <div className="centered-div">
        {props.noteFromOwner ? (
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              textAlign: "center",
              width: "70%",
              margin: "0 auto",
            }}
          >
            <br />
            {props.noteFromOwner}
          </div>
        ) : (
          <></>
        )}
        <br />
        {renderStatus()}
        {errorMessage && <div className="errorDiv">{errorMessage}</div>}
        <div className="header">How would you rate your experience?</div>
        <StarRating
          rating={rating}
          startValue={props.review.rating}
          onChange={handleRatingChange}
          firstReview={props.firstReview}
        />

        <div className="header">Month & Year of Your Stay</div>
        <DateOfStaySelect
          dateOfStay={dateOfStay}
          startValue={props.review.dateOfStay}
          onChange={handleDateOfStayChange}
          firstReview={props.firstReview}
        />

        <div className="header">Title Your Review</div>
        <InputText
          id="reviewTitle"
          rows="1"
          value={reviewTitle}
          startValue={props.review.reviewTitle}
          maxCharacters={120}
          onChange={handleReviewTitleChange}
          firstReview={props.firstReview}
        />

        <div className="header">Write Your Review</div>
        <InputText
          id="reviewBody"
          rows="4"
          value={reviewBody}
          startValue={props.review.reviewBody}
          multiline={true}
          maxCharacters={2000}
          onChange={handleReviewBodyChange}
          firstReview={props.firstReview}
        />

        <br />

        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          style={{
            width: "20%",
            display: props.status === "notPending" ? "none" : "",
          }}
        >
          Submit
        </Button>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default WriteReview;
