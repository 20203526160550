import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";

const PhoneNumberInput = (props) => {
  const inputRef = useRef(null);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [formattedValue, setFormattedValue] = useState("");

  useEffect(() => {
    setFormattedValue(formatPhoneNumber(props.defaultValue));
    setIsValueChanged(false);
  }, [props.defaultValue]);

  const formatPhoneNumber = (value) => {
    if (value && value.length >= 3) {
      let formattedValue = `(${value.slice(0, 3)}`;

      if (value.length > 3) {
        formattedValue += `) ${value.slice(3, 6)}`;

        if (value.length > 6) {
          formattedValue += ` - ${value.slice(6)}`;
        }
      }

      return formattedValue;
    }

    return value;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters

    if (inputValue.length > 10) return;

    let formattedValue = formatPhoneNumber(inputValue);

    setFormattedValue(formattedValue);
    setIsValueChanged(inputValue !== props.defaultValue);

    // Call the onChange prop if it exists, and pass the numeric value
    if (props.onChange) {
      props.onChange(inputValue);
    }

    // If user deletes anything inside the parentheses, keep the hyphen
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      const lastChar = formattedValue.charAt(formattedValue.length - 1);
      const secondLastChar = formattedValue.charAt(formattedValue.length - 2);
      if (lastChar === ")" && secondLastChar === "(") {
        setFormattedValue(formattedValue.slice(0, -2));
      } else if (formattedValue === "() ") {
        // Keep a single set of parentheses when user deletes content inside
        setFormattedValue("(");
      }
    }
  };

  return (
    <TextField
      id={props.id}
      label={props.label}
      variant="filled"
      InputLabelProps={{ shrink: true }}
      value={formattedValue}
      inputRef={inputRef}
      onChange={handleInputChange}
      sx={{
        width: "100%",
        "& .MuiInputBase-root": {
          backgroundColor: "#f0f0f0",
          padding: "8px 16px",
          fontSize: "1.4em",
          color: isValueChanged ? "#c42525" : "black",
        },
        "& .MuiInputLabel-root": {
          color: "#999",
          fontWeight: "bold",
          fontSize: "1.4em",
        },
        "& .MuiInput-underline:before": {
          borderBottom: "none",
        },
        "& .MuiInput-underline:after": {
          borderBottom: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "none",
        },
      }}
    />
  );
};

export default PhoneNumberInput;
