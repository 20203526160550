import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";

const InputFile = (props) => {
  const inputRef = useRef(null); // Ref to hold reference to input field
  const [isValueChanged, setIsValueChanged] = useState(false); // State variable to track value changes

  const handleInputChange = (event) => {
    setIsValueChanged(event.target.value !== props.defaultValue); // Check if input value has changed from original value
  };

  return (
    <div>
      <TextField
        id={props.id}
        label={props.label}
        variant="filled"
        InputLabelProps={{ shrink: true }}
        defaultValue={props.defaultValue} // Use defaultValue instead of value
        inputRef={inputRef} // Pass ref to input field
        onChange={handleInputChange} // Set onChange event handler to update input field directly
        type="file"
        inputProps={{ multiple: true }}
        accept="image/*"
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: "#f0f0f0", // Change background color when value is changed
            padding: "8px 16px",
            fontSize: "1.4em",
            color: isValueChanged ? "#c42525" : "black",
            border: "none",
            borderRadius: "4px",
            boxShadow: "none",
          },
          "& .MuiInputLabel-root": {
            color: "#999", // Change label color when value is changed
            fontWeight: "bold",
            fontSize: "1.4em",
          },
          "& .MuiInput-underline:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
      />
    </div>
  );
};

export default InputFile;
