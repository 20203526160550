import React, { useRef, useState } from "react";
import InputText from "../../Form/InputText";
import InputTextArea from "../../Form/InputTextArea";
import InputSelect from "../../Form/InputSelect";
import InputFile from "../../Form/InputFile";
import ImageList from "../../Form/ImageList";
import SaveButton from "../../Form/SaveButton";
import Button from "@mui/material/Button";
import {
  addToSlideshow,
  createJson,
  saveHome,
  replaceOwnerImage,
} from "./SubmitHandler.js";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const House = (props) => {
  const [amenityList, setAmenityList] = useState(props.home.amenities);
  const images = props.slideshowImages || [];

  const addImage = () => {
    const files = document.getElementById("image").files;

    if (files && files.length > 0) {
      addToSlideshow(files);
    }
  };

  const replaceOwner = () => {
    const image = document.getElementById("newOwnerImage");
    const url = props.ownerImage ? props.ownerImage.path : "";
    replaceOwnerImage(image, url);
  };

  const handleSave = () => {
    const title = document.getElementById("title").value;
    const bedrooms = document.getElementById("bedrooms").innerHTML;
    const bathrooms = document.getElementById("bathrooms").innerHTML;
    const guests = document.getElementById("guests").innerHTML;
    const nightsMin = document.getElementById("nightsMin").innerHTML;
    const overviewType = document.getElementById("overviewType").value;
    const overview = document.getElementById("overview").value;
    const pets = document.getElementById("pets").innerHTML;
    const smoking = document.getElementById("smoking").innerHTML;
    const kids = document.getElementById("kids").innerHTML;
    const overviewbathrooms =
      document.getElementById("overviewbathrooms").value;
    const overviewbedrooms = document.getElementById("overviewbedrooms").value;
    const ownerdisplayname = document.getElementById("ownerdisplayname").value;
    const ownernote = document.getElementById("ownernote").value;
    const ownerlanguages = document.getElementById("ownerlanguages").value;
    const ownerresponsetime =
      document.getElementById("ownerresponsetime").value;
    const listedsince = document.getElementById("listedsince").value;
    const interaction = document.getElementById("interaction").value;
    const abouttheneighborhood = document.getElementById(
      "abouttheneighborhood"
    ).value;
    const googleMapsSRC = document.getElementById("googleMapsSRC").value;
    const gettingaround = document.getElementById("gettingaround").value;
    const access = document.getElementById("access").value;
    const howtogetthere = document.getElementById("howtogetthere").value;
    const activitiesnearby = document.getElementById("activitiesnearby").value;
    const cancellationPolicy =
      document.getElementById("cancellationpolicy").value;
    const noteFromOwner = document.getElementById("noteFromOwner").value;
    let amenityArray = [];
    for (let i = 0; i < amenityList.length; i++) {
      if (
        document.getElementById("amenity" + i).style.textDecoration !==
        "line-through"
      )
        amenityArray.push(amenityList[i]);
    }

    const json = createJson(
      title,
      bedrooms,
      bathrooms,
      guests,
      nightsMin,
      overviewType,
      overview,
      pets,
      smoking,
      kids,
      overviewbathrooms,
      overviewbedrooms,
      ownerdisplayname,
      ownernote,
      ownerlanguages,
      ownerresponsetime,
      listedsince,
      interaction,
      amenityArray,
      props.home.reviews,
      googleMapsSRC,
      abouttheneighborhood,
      gettingaround,
      access,
      howtogetthere,
      activitiesnearby,
      cancellationPolicy,
      noteFromOwner,
      props.home.unavailableDates,
      props.home.reviewOwnersNote
    );

    saveHome(json);
  };

  const moveAmenity = (dragIndex, hoverIndex) => {
    const newAmenityList = [...amenityList];
    const draggedAmenity = newAmenityList[dragIndex];

    newAmenityList.splice(dragIndex, 1);
    newAmenityList.splice(hoverIndex, 0, draggedAmenity);

    setAmenityList(newAmenityList);
  };

  const DraggableAmenity = ({ amenity, index, moveAmenity }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "amenity",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));

    const [, drop] = useDrop({
      accept: "amenity",
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        if (
          (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
          (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
        ) {
          return;
        }

        // Time to actually perform the action
        moveAmenity(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    });

    const ref = useRef(null);
    drag(drop(ref));

    return (
      <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <td style={{ cursor: "pointer" }} title={"Drag to re-order"}>
          <p
            id={"amenity" + index}
            style={{
              color: props.home.amenities.includes(amenity)
                ? "black"
                : "#c42525",
            }}
          >
            {amenity}
          </p>
        </td>
        <td>
          <Button
            id={"amenityReAdd" + index}
            onClick={() => reAddAmenity(index)}
            style={{ display: "none" }}
            variant="outlined"
          >
            Re-add
          </Button>
          <Button
            id={"amenityDelete" + index}
            onClick={() => deleteAmenity(index)}
            variant="outlined"
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  };

  const addAmenityToList = () => {
    const newAmenity = document.getElementById("amenity").value;
    setAmenityList([...amenityList, newAmenity]);
    document.getElementById("amenity").value = "";
  };

  const deleteAmenity = (index) => {
    const amenityToDelete = document.getElementById("amenity" + index);
    amenityToDelete.style.textDecoration = "line-through";
    const buttonToChange1 = document.getElementById("amenityReAdd" + index);
    buttonToChange1.style.display = "";
    const buttonToChange2 = document.getElementById("amenityDelete" + index);
    buttonToChange2.style.display = "none";

    if (props.home.amenities.includes(amenityToDelete.innerHTML))
      amenityToDelete.style.color = "#c42525";
    else amenityToDelete.style.color = "black";
  };

  const reAddAmenity = (index) => {
    const amenityToReAdd = document.getElementById("amenity" + index);
    amenityToReAdd.style.textDecoration = "";
    const buttonToChange1 = document.getElementById("amenityReAdd" + index);
    buttonToChange1.style.display = "none";
    const buttonToChange2 = document.getElementById("amenityDelete" + index);
    buttonToChange2.style.display = "";

    if (props.home.amenities.includes(amenityToReAdd.innerHTML))
      amenityToReAdd.style.color = "black";
    else amenityToReAdd.style.color = "#c42525";
  };

  return (
    <div
      className="input-class"
      style={{ marginTop: "1%", marginBottom: "50px" }}
    >
      <DndProvider backend={HTML5Backend}>
        <SaveButton onClick={handleSave} />
        <InputText id="title" label="Title" defaultValue={props.home.title} />

        {/* Add Slideshow Image */}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <InputFile id="image" label="Slideshow Image" />
          </div>
          <div style={{ position: "absolute", right: 20 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={addImage}
              style={{ fontSize: "1.3em" }}
            >
              Add
            </Button>
          </div>
        </div>

        {images ? <ImageList id="images" images={images} /> : <></>}
        <InputSelect
          id="bedrooms"
          label="Bedrooms"
          defaultValue={props.home.overviewCounts.bedrooms}
          menuItems={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
        />
        <InputSelect
          id="bathrooms"
          label="Bathrooms"
          defaultValue={props.home.overviewCounts.bathrooms}
          menuItems={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
        />
        <InputSelect
          id="guests"
          label="Guests"
          defaultValue={props.home.overviewCounts.guests}
          menuItems={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
        />
        <InputSelect
          id="nightsMin"
          label="Nights Minimum"
          defaultValue={props.home.overviewCounts.minNights}
          menuItems={[
            "Minimum stay varies",
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
          ]}
        />
        <InputText
          id="overviewType"
          label="Overview Type"
          defaultValue={props.home.overviewType}
        />
        <InputTextArea
          id="overview"
          label="Overview"
          defaultValue={props.home.overview}
        />
        <InputSelect
          id="pets"
          label="Pets Allowed?"
          defaultValue={props.home.overviewRules.petsAllowed}
          menuItems={["Yes", "No"]}
        />
        <InputSelect
          id="smoking"
          label="Smoking Allowed?"
          defaultValue={props.home.overviewRules.smokingAllowed}
          menuItems={["Yes", "No"]}
        />
        <InputSelect
          id="kids"
          label="Kid Friendly?"
          defaultValue={props.home.overviewRules.kidFriendly}
          menuItems={["Yes", "No"]}
        />
        <InputText
          id="overviewbathrooms"
          label="Overview Bathrooms"
          defaultValue={props.home.overviewBathrooms}
        />
        <InputText
          id="overviewbedrooms"
          label="Overview Bedrooms"
          defaultValue={props.home.overviewBedrooms}
        />

        {/* Replace Owner Image */}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <InputFile id="newOwnerImage" label="Owner Image" />
          </div>
          <div style={{ position: "absolute", right: 20 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={replaceOwner}
              style={{ fontSize: "1.3em" }}
            >
              Replace
            </Button>
          </div>
        </div>
        {props.ownerImage ? (
          <img
            src={props.ownerImage.path}
            alt="Owner"
            height={"200"}
            width={"200"}
            style={{ display: props.ownerImage ? "" : "none" }}
          />
        ) : (
          <></>
        )}
        <InputText
          id="ownerdisplayname"
          label="Owner Display Name"
          defaultValue={props.home.owner.displayName}
        />
        <InputText
          id="ownernote"
          label="Owner Note"
          defaultValue={props.home.owner.note}
        />
        <InputText
          id="ownerlanguages"
          label="Owner Languages"
          defaultValue={props.home.owner.languagesSpoken}
        />
        <InputText
          id="ownerresponsetime"
          label="Owner Response Time"
          defaultValue={props.home.owner.responseTime}
        />
        <InputText
          id="listedsince"
          label="Listed Since"
          defaultValue={props.home.owner.listedSince}
        />
        <InputText
          id="interaction"
          label="Interaction With Guests"
          defaultValue={props.home.owner.interaction}
        />
        <InputText
          id="googleMapsSRC"
          label="Google Maps SRC"
          defaultValue={props.home.map.googleMapsSRC}
        />
        <InputText
          id="abouttheneighborhood"
          label="About the neighborhood"
          defaultValue={props.home.map.aboutTheNeighborhood}
        />
        <InputText
          id="gettingaround"
          label="Getting around"
          defaultValue={props.home.map.gettingAround}
        />
        <InputText
          id="access"
          label="Access"
          defaultValue={props.home.map.access}
        />
        <InputText
          id="howtogetthere"
          label="How to get there"
          defaultValue={props.home.map.howToGetThere}
        />
        <InputText
          id="activitiesnearby"
          label="Activities nearby"
          defaultValue={props.home.map.activitiesNearby}
        />
        <InputTextArea
          id="cancellationpolicy"
          label="Cancellation Policy"
          defaultValue={props.home.cancellationPolicy}
        />
        <InputTextArea
          id="noteFromOwner"
          label="Note From Owner"
          defaultValue={props.home.noteFromOwner}
        />

        {/* Add Amenity Stuff */}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <InputText
              id="amenity"
              label="Amenity"
              defaultValue={""}
              style={{ paddingRight: "40px" }}
            />
          </div>
          <div style={{ position: "absolute", right: 20 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={addAmenityToList}
              style={{ fontSize: "1.3em" }}
            >
              Add
            </Button>
          </div>
        </div>

        <table style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <th>Amenity</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {amenityList.map((amenity, index) => (
              <DraggableAmenity
                key={index}
                amenity={amenity}
                index={index}
                moveAmenity={moveAmenity}
              />
            ))}
          </tbody>
        </table>
      </DndProvider>
    </div>
  );
};

export default House;
