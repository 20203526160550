import Axios from "axios";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

export const saveFees = async (json) => {
  const token = localStorage.getItem("token");

  try {
    await Axios.post(apiUrl + "/rates/saveFees", json, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      // Check if the error response contains a message
      return error.response.data.message;
    } else {
      // Fallback to the generic error message
      return "Error saving fees: " + error.message;
    }
  }
};

export const createRate = async (json) => {
  const token = localStorage.getItem("token");

  try {
    await Axios.post(apiUrl + "/rates/add", json, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      // Check if the error response contains a message
      return error.response.data.message;
    } else {
      // Fallback to the generic error message
      return "Error saving rate: " + error.message;
    }
  }
};

export const editRate = async (json) => {
  const token = localStorage.getItem("token");

  try {
    await Axios.put(apiUrl + "/rates/edit", json, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      // Check if the error response contains a message
      return error.response.data.message;
    } else {
      // Fallback to the generic error message
      return "Error editing rate: " + error.message;
    }
  }
};

export const deleteRate = (id) => {
  const token = localStorage.getItem("token");
  Axios.delete(apiUrl + `/rates/delete?id=${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(() => {
      window.location.reload();
    })
    .catch();
};

export const deleteRates = (ids) => {
  const token = localStorage.getItem("token");
  Axios.delete(apiUrl + `/rates/delete-multiple`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: { ids: ids },
  })
    .then(() => {
      window.location.reload();
    })
    .catch();
};
