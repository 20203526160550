import React from "react";
import "../styles.css";
import "./amenity-styles.css";

function Amenities(props) {
  const amenities = props.amenities;

  return (
    <div>
      <div className="header-large">Amenities</div>
      <hr></hr>
      <div className="container-amenity">
          {amenities.map((amenity, i) => (
            <div key={i} className="item-amenity">
              <span className="item-text-a">✔</span>
              <span className="item-text-a">{amenity}</span>
            </div>
          ))}
        </div>
      </div>
  );
}

export default Amenities;
