import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import SaveButton from "../../Form/SaveButton";
import BigCalendar from "./BigCalendar";
import {
  saveDatesUnavailable,
  removeDateUnavailable,
  removeDatesUnavailable,
} from "../House/SubmitHandler.js";
import "../admin.css";

function DatesUnavailable(props) {
  //
  // Adding Dates Unavailable
  //
  const [showAddDatesUnavailableDiv, setShowAddDatesUnavailableDiv] =
    useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );

  // When User clicks add unavailable dates
  const handleAddDatesUnavailableClick = () => {
    if (!showAddDatesUnavailableDiv) setShowAddDatesUnavailableDiv(true);
    else setShowAddDatesUnavailableDiv(false);
  };

  // When user clicks confirm in the modal
  const handleAddDatesUnavailableConfirm = () => {
    saveDatesUnavailable(selectedStartDate, selectedEndDate);
  };

  const handleDateChange = (date) => {
    if (date) {
      setSelectedStartDate(date[0]);
      setSelectedEndDate(date[1]);
    }
  };

  //
  // Delete Icon Stuff
  //
  const [selectedDateToDelete, setSelectedDateToDelete] = useState([]);
  const [
    showDeleteSingleConfirmationModal,
    setShowDeleteSingleConfirmationModal,
  ] = useState(false);

  // When the user clicks the delete icon (trash can)
  const handleDeleteSingleClick = (date) => {
    setSelectedDateToDelete(date);
    setShowDeleteSingleConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleDeleteSingleCancel = () => {
    setShowDeleteSingleConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleDeleteSingleConfirm = () => {
    removeDateUnavailable(selectedDateToDelete);
    setShowDeleteSingleConfirmationModal(false);
  };

  //
  // Delete Selected
  //
  const [checkAll, setCheckAll] = useState(false);
  const [selectedDatesToDelete, setSelectedDatesToDelete] = useState([]);
  const [
    showDeleteSelectedConfirmationModal,
    setShowDeleteSelectedConfirmationModal,
  ] = useState(false);

  // When the top checkbox is clicked
  const handleCheckAll = (event) => {
    setCheckAll(event.target.checked);
    const checkboxes = document.querySelectorAll(
      'tbody input[type="checkbox"][name="unavailableCheckbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
    });

    setSelectedDatesToDelete(
      Array.from(
        document.querySelectorAll(
          'tbody input[type="checkbox"][name="unavailableCheckbox"]:checked'
        )
      ).map((checkbox) => checkbox.id)
    );
  };

  // When a checkbox is clicked
  const handleCheckbox = (event) => {
    const checkboxes = document.querySelectorAll(
      'tbody input[type="checkbox"][name="unavailableCheckbox"]'
    );
    const checkedCount = document.querySelectorAll(
      'tbody input[type="checkbox"][name="unavailableCheckbox"]:checked'
    ).length;
    setCheckAll(checkedCount === checkboxes.length);

    setSelectedDatesToDelete(
      Array.from(
        document.querySelectorAll(
          'tbody input[type="checkbox"][name="unavailableCheckbox"]:checked'
        )
      ).map((checkbox) => checkbox.id)
    );
  };

  // When the user clicks delete selected dates
  const handleDeleteSelectedClick = () => {
    setShowDeleteSelectedConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleDeleteSelectedCancel = () => {
    setShowDeleteSelectedConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleDeleteSelectedConfirm = () => {
    removeDatesUnavailable(selectedDatesToDelete);
    setShowDeleteSelectedConfirmationModal(false);
  };

  // Dates Unavailable Table
  const rows = props.datesUnavailable.map((row, index) => {
    return (
      <tr key={row} className={index % 2 === 0 ? "even-row" : "odd-row"}>
        <td>
          <input
            type="checkbox"
            name="unavailableCheckbox"
            id={row}
            onChange={handleCheckbox}
          />
        </td>
        <td>{row}</td>
        <td>
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteSingleClick(row)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div style={{ width: "100%", margin: "0 auto", marginTop: "3%" }}>
        <div>
          <BigCalendar datesUnavailable={props.datesUnavailable} />
        </div>
        <br />
        <br />
        <br />
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddDatesUnavailableClick}
          >
            Add Unavailable Dates
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteSelectedClick}
            disabled={selectedDatesToDelete.length === 0}
          >
            Delete Selected Dates
          </Button>
        </div>
        {showAddDatesUnavailableDiv ? (
          <div style={{ width: "100%", margin: "0 auto" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <DateRangePicker
                placeholder={"Start Date - End Date"}
                format="MM/dd/yyyy"
                character=" – "
                onChange={handleDateChange}
                shouldDisableDate={(date) =>
                  props.datesUnavailable.some(
                    (dateString) =>
                      date.toDateString() ===
                      new Date(dateString).toDateString()
                  )
                }
                showOneCalendar
                editable={false}
                showHeader={false}
              />
              <SaveButton noStyle onClick={handleAddDatesUnavailableConfirm} />
            </div>
          </div>
        ) : null}

        <table className="admin-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={checkAll}
                  onChange={handleCheckAll}
                  id="checkAll"
                />
              </th>
              <th className="header-cell">Unavailable Dates:</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>

      <Dialog
        open={showDeleteSingleConfirmationModal}
        onClose={handleDeleteSingleCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to make the date "{selectedDateToDelete}"{" "}
          available?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSingleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSingleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteSelectedConfirmationModal}
        onClose={handleDeleteSelectedCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to make these dates available?
          {selectedDatesToDelete.map((item, index) => (
            <div key={index}>"{item}"</div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSelectedCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSelectedConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DatesUnavailable;
