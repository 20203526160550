import Axios from "axios";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

export const confirmReview = (id) => {
  const token = localStorage.getItem("token");
  Axios.post(
    apiUrl + "/pending-reviews/confirm",
    {
      id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then(() => {
      window.location.reload();
    })
    .catch();
};

export const unconfirmReview = (id) => {
  const token = localStorage.getItem("token");
  Axios.post(
    apiUrl + "/pending-reviews/unconfirm",
    {
      id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then(() => {
      window.location.reload();
    })
    .catch();
};

export const deleteReview = (id) => {
  const token = localStorage.getItem("token");
  Axios.delete(apiUrl + `/pending-reviews/delete?id=${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(() => {
      window.location.reload();
    })
    .catch();
};
