import React from 'react';
import './styles.css';

function Title(props) {

  return (
    <div className='title'>
      {props.title}
    </div>
  );
}

export default Title;