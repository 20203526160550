// PrivateRouteAdmin.js
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../Hooks/AuthContext";

const PrivateRouteAdmin = ({ routes }) => {
  const { isAuthenticated, loading, user, checkAuthStatus } = useAuth();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await checkAuthStatus();
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    if (!loading) {
      checkAuthentication();
    }
  }, [checkAuthStatus, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Check if user is authenticated and has the role of "admin"
  const isAdmin = isAuthenticated && user && user.role === "admin";

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAdmin ? routes : <Navigate to={isAuthenticated ? "/" : "/login"} />
        }
      />
    </Routes>
  );
};

export default PrivateRouteAdmin;
