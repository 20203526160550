import React from "react";
import "./overview-styles.css";
import "../styles.css";

const HouseRules = (props) => {
  const cellStyle = { marginTop: "1%" };

  return (
    <div>
      <div>
        <div className="header-mid" style={{ marginTop: "5%" }}>
          House Rules
        </div>
        <div style={cellStyle} className="main-text">
          Pets Allowed:{" "}
          {props.overviewRules.petsAllowed === "Yes" ? "Yes" : "No"}
        </div>
        <div style={cellStyle} className="main-text">
          Smoking Allowed:{" "}
          {props.overviewRules.smokingAllowed === "Yes" ? "Yes" : "No"}
        </div>
        <div style={cellStyle} className="main-text">
          Kid Friendly:{" "}
          {props.overviewRules.kidFriendly === "Yes" ? "Yes" : "No"}
        </div>

        <div className="header-mid" style={{ marginTop: "5%" }}>
          Bathrooms
        </div>
        <div className="main-text">{props.overviewBathrooms}</div>

        <div className="header-mid" style={{ marginTop: "5%" }}>
          Bedrooms
        </div>
        <div className="main-text">{props.overviewBedrooms}</div>
      </div>
    </div>
  );
};

export default HouseRules;
