import { useState, useEffect } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function useOwnerImage() {
  const [image, setImage] = useState();

  useEffect(() => {
    const getOwnerImage = async () => {
      try {
        const response = await fetch(apiUrl + `/owner/`);
        const json = await response.json();

        if (JSON.stringify(json) === JSON.stringify([null])) {
          setImage();
          return;
        }

        if (!response.ok) {
          return;
        }

        setImage(json);
      } catch (error) {
        return;
      }
    };

    getOwnerImage();
  }, []);

  return image;
}

export default useOwnerImage;
