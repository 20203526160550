import Axios from "axios";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

export const saveReview = async (json) => {
  const token = localStorage.getItem("token");

  try {
    await Axios.post(apiUrl + "/pending-reviews/add", json, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
    return "Success";
  } catch (error) {
    console.log(apiUrl);
    if (error.response && error.response.data && error.response.data.message) {
      // Check if the error response contains a message
      return error.response.data.message;
    } else {
      // Fallback to the generic error message
      return "Error saving review: " + error.message;
    }
  }
};

export const editReview = async (json) => {
  const token = localStorage.getItem("token");

  try {
    await Axios.put(apiUrl + `/pending-reviews/edit/`, json, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.reload();
    return "Success";
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      // Check if the error response contains a message
      return error.response.data.message;
    } else {
      // Fallback to the generic error message
      return "Error saving review: " + error.message;
    }
  }
};
