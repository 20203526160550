import App from "../.././App";
import React from "react";

function Preview() {
  return (
    <div>
      <App preview="Y" />
    </div>
  );
}

export default Preview;
