import { useState, useEffect } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function usePendingReviews() {
  const [reviews, setReviews] = useState();

  useEffect(() => {
    const getReviews = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await fetch(apiUrl + `/pending-reviews/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const json = await response.json();

        if (!response.ok) {
          return;
        }

        setReviews(json);
      } catch (error) {
        return;
      }
    };

    getReviews();
  }, []);

  return reviews;
}

export default usePendingReviews;
