import { useState, useEffect } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function useReviews() {
  const [reviews, setReviews] = useState();

  useEffect(() => {
    const getReviews = async () => {
      try {
        const response = await fetch(apiUrl + `/reviews/`);
        const json = await response.json();

        if (!response.ok) {
          return;
        }

        setReviews(json);
      } catch (error) {
        return;
      }
    };

    getReviews();
  }, []);

  return reviews;
}

export default useReviews;
