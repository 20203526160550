// Import React
import React from "react";

// Import other components
import Navigation from "./components/User/Navigation";
import WriteReview from "./components/User/Reviews/WriteReview";
import Profile from "./components/User/Profile";
import CreateInquiry from "./components/User/Inquiries/CreateInquiry";
import Inquiries from "./components/User/Inquiries/Inquiries";

// Import hooks
import useHome from "./components/Hooks/useHome";
import { useAuth } from "./components/Hooks/AuthContext";
import useSingleReview from "./components/Hooks/useSingleReview";
import useUserInquiries from "./components/Hooks/useUserInquiries";
import useFees from "./components/Hooks/useFees";
import useRates from "./components/Hooks/useRates";
import useDatesUnavailable from "./components/Hooks/useDatesUnavailable";

function AppUser(props) {
  const home = useHome();
  const { user } = useAuth();
  const review = useSingleReview();
  const inquiries = useUserInquiries();
  const fees = useFees();
  const rates = useRates();
  const datesUnavailable = useDatesUnavailable();

  const defaultReview = {
    email: "",
    rating: 5,
    dateOfStay: "",
    reviewTitle: "",
    reviewBody: "",
  };

  return (
    <div style={{ marginRight: "2%", marginLeft: "2%" }}>
      {home && <Navigation title={home.title} />}
      {props.page === "profile" && user && <Profile user={user} />}
      {props.page === "inquiries" && user && inquiries && (
        <Inquiries user={user} inquiries={inquiries} />
      )}
      {props.page === "write-review" &&
        review &&
        review.review &&
        user &&
        home && (
          <WriteReview
            review={review.review}
            user={user}
            status={review.status}
            noteFromOwner={home.reviewOwnersNote}
          />
        )}

      {props.page === "make-inquiry" &&
        user &&
        fees &&
        rates &&
        datesUnavailable && (
          <CreateInquiry
            user={user}
            fees={fees}
            rates={rates}
            datesUnavailable={datesUnavailable}
          />
        )}

      {props.page === "write-review" &&
        (!review || !review.review) &&
        user &&
        home && (
          <WriteReview
            user={user}
            review={defaultReview}
            firstReview={true}
            noteFromOwner={home.reviewOwnersNote}
          />
        )}
    </div>
  );
}

export default AppUser;
