import React, { useRef, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const InputText = (props) => {
  const inputRef = useRef(null);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [value, setValue] = useState(props.value ? props.value : "");

  useEffect(() => {
    if (props.onChange) {
      props.onChange(value);
    }
  }, [value, props.onChange, props]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    inputRef.current.value = inputValue;
    setIsValueChanged(inputValue !== props.startValue && !props.firstReview);
    setValue(inputValue);
  };

  const characterCount = value.length;
  const minCharacterMessage = (
    <>
      {characterCount} / {props.minCharacters} min characters
    </>
  );
  const maxCharacterMessage = (
    <>
      {characterCount} / {props.maxCharacters} max characters
    </>
  );

  return (
    <div>
      <TextField
        id={props.id}
        defaultValue={props.value}
        inputRef={inputRef}
        onChange={handleInputChange}
        multiline={props.multiline}
        maxRows={50}
        placeholder={props.placeholder}
        sx={{
          width: "70%",
          "& .MuiInputBase-root": {
            color: isValueChanged ? "#c42525" : "inherit",
          },
        }}
        inputProps={{
          maxLength: props.maxCharacters > 0 ? props.maxCharacter : 2000,
          style: {
            overflow: "hidden",
            resize: "none",
          },
        }}
      />
      <Typography
        variant="body2"
        color={
          characterCount <= props.minCharacters
            ? "textSecondary"
            : "error" || characterCount > props.maxCharacters
            ? "textSecondary"
            : "error"
        }
      >
        {props.minCharacters > 0 ? minCharacterMessage : ""}
        {props.maxCharacters > 0 ? maxCharacterMessage : ""}
      </Typography>
    </div>
  );
};

export default InputText;
