import React from 'react';
import './styles.css';

function CancellationPolicy(props) {
  return (
    <div>
        <div className='header-large'>Cancellation Policy</div>
        <hr></hr>
        <div className='main-text' style={{ whiteSpace: "pre-line" }}>{props.cancellationPolicy}</div>
    </div>
  );
}

export default CancellationPolicy;