import React from "react";
import "./styles.css";

function Map(props) {
  const iframeSrc = props.map.googleMapsSRC;

  // Regular expression to extract the URL from the src attribute
  const regex = /src="(.*?)"/;
  const match = iframeSrc.match(regex);

  // Extracted URL
  const extractedURL = match ? match[1] : null;

  return (
    <div>
      <div className="header-large">Map</div>
      <hr></hr>
      <iframe
        src={extractedURL}
        title="Map"
        width="100%"
        style={{ border: "0}" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="header-sub">About the neighborhood</div>
      <div className="main-text">{props.map.aboutTheNeighborhood}</div>
      <br />
      <div className="header-sub">Getting around</div>
      <div className="main-text">{props.map.gettingAround}</div>
      <br />
      <div className="header-sub">Access</div>
      <div className="main-text">{props.map.access}</div>
      <br />
      <div className="header-sub">How to get there</div>
      <div className="main-text">{props.map.gettingAround}</div>
      <br />
      <div className="header-sub">Activities nearby</div>
      <div className="main-text">{props.map.activitiesNearby}</div>
    </div>
  );
}

export default Map;
