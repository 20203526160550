// YourApp.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "./components/Hooks/AuthContext";
import PrivateRoute from "./components/Admin/PrivateRoute";
import PrivateRouteAdmin from "./components/Admin/PrivateRouteAdmin";
import App from "./App";
import AppAdmin from "./AppAdmin";
import AppUser from "./AppUser";
import LoginPage from "./components/Login/LoginPage";
import CreateAccount from "./components/Login/CreateAccount";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<CreateAccount />} />
          {/* Use the routes inside PrivateRoute instead of element */}
          <Route
            path="/admin/house/*"
            element={<PrivateRouteAdmin routes={<AppAdmin page={"house"} />} />}
          />
          <Route
            path="/admin/bookings/*"
            element={
              <PrivateRouteAdmin routes={<AppAdmin page={"bookings"} />} />
            }
          />
          <Route
            path="/admin/reviews/*"
            element={
              <PrivateRouteAdmin routes={<AppAdmin page={"reviews"} />} />
            }
          />
          <Route
            path="/admin/preview/*"
            element={
              <PrivateRouteAdmin routes={<AppAdmin page={"preview"} />} />
            }
          />
          <Route
            path="/admin/profile/*"
            element={
              <PrivateRouteAdmin routes={<AppAdmin page={"profile"} />} />
            }
          />
          <Route
            path="/admin/inquiries/*"
            element={
              <PrivateRouteAdmin routes={<AppAdmin page={"inquiries"} />} />
            }
          />
          <Route
            path="/admin/rates/*"
            element={<PrivateRouteAdmin routes={<AppAdmin page={"rates"} />} />}
          />
          <Route
            path="/write-review/*"
            element={
              <PrivateRoute routes={<AppUser page={"write-review"} />} />
            }
          />
          <Route
            path="/make-inquiry/*"
            element={
              <PrivateRoute routes={<AppUser page={"make-inquiry"} />} />
            }
          />
          <Route
            path="/inquiries/*"
            element={<PrivateRoute routes={<AppUser page={"inquiries"} />} />}
          />
          <Route
            path="/profile/*"
            element={<PrivateRoute routes={<AppUser page={"profile"} />} />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);
