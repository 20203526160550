import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";

const InputText = (props) => {
  const inputRef = useRef(null);
  const [isValueChanged, setIsValueChanged] = useState(false);

  const handleInputChange = (event) => {
    inputRef.current.value = event.target.value;
    setIsValueChanged(event.target.value !== props.defaultValue);
  };

  return (
    <div>
      <TextField
        id={props.id}
        label={props.label}
        variant="filled"
        multiline
        rows={4}
        InputLabelProps={{ shrink: true }}
        defaultValue={props.defaultValue}
        inputRef={inputRef}
        onChange={handleInputChange}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            backgroundColor: "#f0f0f0",
            padding: "8px 16px",
            fontSize: "1.4em",
            color: isValueChanged ? "#c42525" : "black",
            paddingTop: "35px", // Adjust these values as needed
            paddingBottom: "0px", // Adjust these values as needed
            cursor: "auto",
            "& .MuiInputBase-input": {
              resize: "vertical",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#999",
            fontWeight: "bold",
            fontSize: "1.4em",
          },
          "& .MuiInput-underline:before": {
            borderBottom: "none"
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none"
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none"
          }
        }}
      />
    </div>
  );
};

export default InputText;
