import React from "react";
import "./user-styles.css";
import InputText from "../Form/InputText";
import InputTextPassword from "../Form/InputTextPassword.jsx";
import PhoneNumberInput from "../Form/PhoneNumberInput";
import Button from "@mui/material/Button";
import { editUser, changePassword } from "./UserHandler";
let validator = require("validator");

function Profile(props) {
  const submitUser = async () => {
    let errorMessage = "";

    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const phoneNumberWithExtra = document.getElementById("phoneNumber").value;
    const email = document.getElementById("email").value;

    const phoneNumber = phoneNumberWithExtra.replace(/\D/g, "");

    if (
      !/^[a-zA-Z\s]+$/.test(firstName) ||
      firstName.length > 40 ||
      firstName.lengh < 2
    )
      errorMessage = "Invalid First Name";
    if (
      !/^[a-zA-Z\s]+$/.test(lastName) ||
      lastName.length > 40 ||
      lastName.length < 2
    )
      errorMessage = "Invalid Last Name";
    if (!/^\d+$/.test(phoneNumber) || phoneNumber.length !== 10)
      errorMessage = "Invalid Phone Number";
    if (!validator.isEmail(email)) errorMessage = "Invalid Email";

    if (errorMessage.length > 0) {
      const errorDiv = document.getElementById("errorDiv");
      errorDiv.style.display = "block";
      errorDiv.innerHTML = "Error: " + errorMessage;
      errorDiv.className = "errorDiv";
      return;
    }

    const updatedData = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
    };

    const json = {
      email: props.user.email,
      updatedData: updatedData,
    };

    const message = await editUser(json);

    if (message === "Success") {
      const errorDiv = document.getElementById("errorDiv");
      errorDiv.style.display = "block";
      errorDiv.innerHTML = "User saved successfully";
      errorDiv.className = "errorDivSuccess";
    } else {
      const errorDiv = document.getElementById("errorDiv");
      errorDiv.style.display = "block";
      errorDiv.innerHTML = "Error: " + message;
      errorDiv.className = "errorDiv";
    }
  };

  const submitPassword = async () => {
    let errorMessage = "";
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const password = document.getElementById("password").value;

    if (password.lengh < 7)
      errorMessage = "Password must be at least 7 characters";
    if (password.lengh < 25)
      errorMessage = "Password cannot be more than 25 characters";
    if (!passwordRegex.test(password))
      errorMessage =
        "Password must contains at least one upper case, one lower case, and one number";

    if (errorMessage.length > 0) {
      const errorDiv = document.getElementById("errorDiv");
      errorDiv.style.display = "block";
      errorDiv.innerHTML = "Error: " + errorMessage;
      errorDiv.className = "errorDiv";
      return;
    }

    const json = {
      newPassword: password,
    };

    const message = await changePassword(json);

    if (message === "Success") {
      const errorDiv = document.getElementById("errorDiv");
      errorDiv.style.display = "block";
      errorDiv.innerHTML = "Password saved successfully";
      errorDiv.className = "errorDivSuccess";
    } else {
      const errorDiv = document.getElementById("errorDiv");
      errorDiv.style.display = "block";
      errorDiv.innerHTML = "Error: " + message;
      errorDiv.className = "errorDiv";
    }
  };

  const removeError = () => {
    const errorDiv = document.getElementById("errorDiv");
    errorDiv.style.display = "none";
  };

  return (
    <div>
      <div className="centered-div">
        <div id="errorDiv" style={{ display: "none" }} className="errorDiv" />
        <InputText
          id="firstName"
          label="First Name"
          defaultValue={props.user.firstName}
          onChange={removeError}
        />
        <InputText
          id="lastName"
          label="Last Name"
          defaultValue={props.user.lastName}
          onChange={removeError}
        />
        <PhoneNumberInput
          id="phoneNumber"
          label="Phone Number"
          defaultValue={props.user.phoneNumber}
          onChange={removeError}
        />
        <InputText
          id="email"
          label="Email"
          defaultValue={props.user.email}
          onChange={removeError}
        />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={submitUser}
          style={{ width: "20%" }}
        >
          Submit
        </Button>
        <br />
        <br />
        <br />
        <br />
        <InputTextPassword
          id="password"
          label="Change Password"
          onChange={removeError}
          defaultValue={""}
        />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={submitPassword}
          style={{ width: "20%" }}
        >
          Submit
        </Button>
      </div>
      <br />
    </div>
  );
}

export default Profile;
