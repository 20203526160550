import React from 'react';
import '../styles.css';
import OverviewCounts from './OverviewCounts';
import HouseRules from './HouseRules';

function Overview(props) {
  return (
    <div>
      <div className='header-large'>Overview</div>
      <hr/>
      <OverviewCounts overviewCounts={props.overviewCounts}/>
      <div className='header-sub'>{props.overviewType}</div>
      <div className='main-text' style={{ whiteSpace: "pre-line" }}>
        {props.overview}
      </div>
      <HouseRules overviewRules={props.overviewRules} overviewBathrooms={props.overviewBathrooms} overviewBedrooms={props.overviewBedrooms}/>
    </div>
  );
}

export default Overview;