import React, { useState, useEffect } from "react";
import "./login-styles.css";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "../Hooks/AuthContext"; // Adjust the path as needed
import PhoneNumberInput from "../Form/InputPhoneNumber2";
let validator = require("validator");

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function CreateAccount(props) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const { login, isAuthenticated } = useAuth();

  useEffect(() => {
    // Redirect to home if the user is authenticated
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleCreateAccountClick = async () => {
    setErrorMessage("");

    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const phoneNumberWithExtra = document.getElementById("phoneNumber").value;
    const email = document.getElementById("email").value;

    const phoneNumber = phoneNumberWithExtra.replace(/\D/g, "");

    if (
      !firstName ||
      !/^[a-zA-Z\s]+$/.test(firstName) ||
      firstName.length > 40 ||
      firstName.lengh < 2
    )
      setErrorMessage("Invalid First Name");

    if (
      !lastName ||
      !/^[a-zA-Z\s]+$/.test(lastName) ||
      lastName.length > 40 ||
      lastName.length < 2
    )
      setErrorMessage("Invalid Last Name");

    if (!phoneNumber || !/^\d+$/.test(phoneNumber) || phoneNumber.length !== 10)
      setErrorMessage("Invalid Phone Number");

    if (!email || !validator.isEmail(email)) setErrorMessage("Invalid Email");

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const password = document.getElementById("password").value;

    if (!password || password.lengh < 7)
      setErrorMessage("Password must be at least 7 characters");

    if (password.lengh < 25)
      setErrorMessage("Password cannot be more than 25 characters");

    if (!passwordRegex.test(password))
      setErrorMessage(
        "Password must contains at least one upper case, one lower case, and one number"
      );

    if (errorMessage || errorMessage.length > 0) return;

    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      phoneNumber.length === 0 ||
      email.length === 0 ||
      password.length === 0
    ) {
      setErrorMessage("All fields are required");
      return;
    }

    var json = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      password: password,
    };

    var credentials = {
      email: email,
      password: password,
    };

    try {
      await Axios.post(apiUrl + "/auth/register", json);

      // Call login and wait for it to complete
      await login(credentials);

      // isAuthenticated will be updated after login completes
      if (isAuthenticated) {
        // Redirect to home if authenticated
        navigate("/");
      }
    } catch (error) {
      setErrorMessage(error.response.data.error);
    }
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className="loginContainer">
      <h1 className="loginHeader">Create Account</h1>

      <div style={{ display: errorMessage ? "block" : "none", color: "red" }}>
        Error creating account: {errorMessage}
        <br />
        <br />
      </div>

      <label htmlFor="firstName">First Name</label>
      <br />
      <input type="text" id="firstName" className="loginTextBox" />
      <br />
      <label htmlFor="lastName">Last Name</label>
      <br />
      <input type="text" id="lastName" className="loginTextBox" />
      <br />
      <label htmlFor="phoneNumber">Phone Number</label>
      <br />
      <PhoneNumberInput id="phoneNumber" defaultValue="" />
      <br />
      <label htmlFor="email">Email</label>
      <br />
      <input type="text" id="email" className="loginTextBox" />
      <br />
      <label htmlFor="password" required>
        Password
      </label>
      <br />
      <input type="password" id="password" className="loginTextBox" />
      <br />

      <button
        className="createAccountButton"
        onClick={handleCreateAccountClick}
      >
        Create Account
      </button>

      <div className="orContainer">
        <hr className="orHr" />
        <span className="orText">OR</span>
        <hr className="orHr" />
      </div>

      <button
        className="login-button"
        style={{ marginTop: "5px", marginBottom: "5px" }}
        onClick={handleLoginClick}
      >
        Log In
      </button>
    </div>
  );
}

export default CreateAccount;
