import React from "react";
import "./styles.css";

function NoteFromOwner(props) {
  return (
    <div>
      <h2 style={{ marginBottom: "1px", marginTop: "5%" }}>Note from owner</h2>
      <div className="main-text">{props.note}</div>
    </div>
  );
}

export default NoteFromOwner;
