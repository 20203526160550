import React from "react";
import "./login-styles.css";
import { useNavigate } from "react-router-dom";

function LoginButton(props) {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div>
      <button onClick={handleLoginClick} className="login-button-fixed">
        Login
      </button>
    </div>
  );
}

export default LoginButton;
