import { useState, useEffect } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function useFees() {
  const [fees, setFees] = useState();

  useEffect(() => {
    const getFees = async () => {
      try {
        const response = await fetch(apiUrl + `/rates/getFees`, {
          method: "GET",
        });
        const json = await response.json();

        if (!response.ok) {
          return;
        }

        setFees(json);
      } catch (error) {
        return;
      }
    };

    getFees();
  }, []);

  return fees;
}

export default useFees;
