import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/EditNote";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  editRate,
  deleteRate,
  deleteRates,
  createRate,
  saveFees,
} from "./RatesHandler";
import SaveButton from "../../Form/SaveButton";

function Rates(props) {
  //
  // Fee Stuff
  //
  const [minNights, setMinNights] = useState(
    props.fees.minNights ? props.fees.minNights : 0
  );
  const [baseWeekly, setBaseWeekly] = useState(
    props.fees.baseWeekly
      ? props.fees.baseWeekly.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        })
      : 0
  );
  const [petFee, setPetFee] = useState(
    props.fees.petFee
      ? props.fees.petFee.toLocaleString("en-US", { maximumFractionDigits: 0 })
      : 0
  );
  const [poolHeater, setPoolHeater] = useState(
    props.fees.poolHeater
      ? props.fees.poolHeater.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        })
      : 0
  );
  const [lodgingTax, setLodgingTax] = useState(
    props.fees.lodgingTax ? props.fees.lodgingTax : 0
  );
  const [bookingFee, setBookingFee] = useState(
    props.fees.bookingFee ? props.fees.bookingFee : 0
  );
  const [cleaningFee, setCleaningFee] = useState(
    props.fees.cleaningFee
      ? props.fees.cleaningFee.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        })
      : 0
  );

  const saveFeesClick = () => {
    const base = baseWeekly
      ? baseWeekly.toString().replace(/,/g, "").replace(/\./g, "")
      : 0;
    const pet = petFee
      ? petFee.toString().replace(/,/g, "").replace(/\./g, "")
      : 0;
    const pool = poolHeater
      ? poolHeater.toString().replace(/,/g, "").replace(/\./g, "")
      : 9;
    const cleaning = cleaningFee
      ? cleaningFee.toString().replace(/,/g, "").replace(/\./g, "")
      : 0;

    const json = {
      minNights: minNights ? minNights : 0,
      baseWeekly: base,
      petFee: pet,
      poolHeater: pool,
      lodgingTax: lodgingTax ? lodgingTax : 0,
      bookingFee: bookingFee ? bookingFee : 0,
      cleaningFee: cleaning,
    };

    saveFees(json);
  };

  const handleMinNightsChange = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers with up to two decimal places
    const regex = /^(?:100|[1-9][0-9]?)$/;

    if (regex.test(value) || value === "") {
      setMinNights(value);
    }
  };

  const handleBaseWeeklyChange = (event) => {
    let { value } = event.target;

    // Remove existing commas and periods from the value
    value = value.replace(/,/g, "").replace(/\./g, "");

    // Use a regular expression to allow only numbers with up to two decimal places
    const regex = /^\d*$/;

    if (regex.test(value) || value === "") {
      setBaseWeekly(value);
    }
  };

  const handlePetFeeChange = (event) => {
    let { value } = event.target;

    // Remove existing commas and periods from the value
    value = value.replace(/,/g, "").replace(/\./g, "");

    // Use a regular expression to allow only numbers with up to two decimal places
    const regex = /^\d*$/;

    if (regex.test(value) || value === "") {
      setPetFee(value);
    }
  };

  const handlePoolHeaterChange = (event) => {
    let { value } = event.target;

    // Remove existing commas and periods from the value
    value = value.replace(/,/g, "").replace(/\./g, "");

    // Use a regular expression to allow only numbers with up to two decimal places
    const regex = /^\d*$/;

    if (regex.test(value) || value === "") {
      setPoolHeater(value);
    }
  };

  const handleLodgingTaxChange = (event) => {
    let { value } = event.target;

    // Use a regular expression to allow only numbers with up to two decimal places
    const regex = /^\d+(\.\d{1,2})?$/;

    if (regex.test(value) || value === "") {
      setLodgingTax(value);
    }
  };

  const handleBookingFee = (event) => {
    let { value } = event.target;

    // Use a regular expression to allow only numbers with up to two decimal places
    const regex = /^\d+(\.\d{1,2})?$/;

    if (regex.test(value) || value === "") {
      setBookingFee(value);
    }
  };

  const handleCleaningFee = (event) => {
    let { value } = event.target;

    // Remove existing commas and periods from the value
    value = value.replace(/,/g, "").replace(/\./g, "");

    // Use a regular expression to allow only numbers with up to two decimal places
    const regex = /^\d*$/;

    if (regex.test(value) || value === "") {
      setCleaningFee(value);
    }
  };

  //
  // Add Rate Stuff
  //
  const [showAddRateModal, setShowAddRateModal] = useState(false);
  const [rateStart, setRateStart] = useState(new Date());
  const [rateEnd, setRateEnd] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [weekly, setWeekly] = useState();
  const [monthly, setMonthly] = useState();
  const [minStay, setMinStay] = useState(1);
  const [discount1, setDiscount1] = useState();
  const [discount2, setDiscount2] = useState();
  const [discount3, setDiscount3] = useState();
  const [discount4, setDiscount4] = useState();

  const handleWeeklyChange = (event) => {
    let { value } = event.target;

    // Remove existing commas and periods from the value
    value = value.replace(/,/g, "").replace(/\./g, "");

    // Use a regular expression to allow only numbers with up to two decimal places
    const regex = /^\d*$/;

    if (regex.test(value) || value === "") {
      setWeekly(value);
    }
  };

  const handleMonthlyChange = (event) => {
    let { value } = event.target;

    // Remove existing commas and periods from the value
    value = value.replace(/,/g, "").replace(/\./g, "");

    // Use a regular expression to allow only numbers with up to two decimal places
    let regex = /^\d*$/;

    if (regex.test(value) || value === "") {
      setMonthly(value);
    }
  };

  const handleMinStayChange = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setMinStay(value);
    }
  };

  const handleDiscount1Change = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setDiscount1(value);
    }
  };

  const handleDiscount2Change = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setDiscount2(value);
    }
  };

  const handleDiscount3Change = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setDiscount3(value);
    }
  };

  const handleDiscount4Change = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setDiscount4(value);
    }
  };

  // When User clicks add rate
  const handleAddRateClick = () => {
    setShowAddRateModal(true);
  };

  // When user clicks cancel in the modal
  const handleAddRateCancel = () => {
    setShowAddRateModal(false);
  };

  // When user clicks confirm in the modal
  const handleAddRateConfirm = () => {
    const json = {
      rateStart: rateStart,
      rateEnd: rateEnd,
      weekly: weekly,
      monthly: monthly,
      minStay: minStay,
      discount1: discount1,
      discount2: discount2,
      discount3: discount3,
      discount4: discount4,
    };

    // Check if the new rate conflicts with existing rates
    const isConflict = props.rates.some((rate) => {
      const existingRateStart = new Date(rate.rateStart);
      const existingRateEnd = new Date(rate.rateEnd);
      const newRateStart = new Date(rateStart);
      const newRateEnd = new Date(rateEnd);

      // Check if the new rate overlaps with any existing rate
      return (
        (newRateStart >= existingRateStart &&
          newRateStart <= existingRateEnd) ||
        (newRateEnd >= existingRateStart && newRateEnd <= existingRateEnd) ||
        (existingRateStart >= newRateStart &&
          existingRateStart <= newRateEnd) ||
        (existingRateEnd >= newRateStart && existingRateEnd <= newRateEnd)
      );
    });

    // If there's a conflict, handle it (show a message, prevent submission, etc.)
    if (isConflict) {
      alert(
        "Error: The new rate conflicts with existing rates. Please choose different dates."
      );
      // You might want to return or handle this case appropriately
      return;
    }

    createRate(json);
    setShowAddRateModal(false);
  };

  const handleStartDateChange = (date) => {
    setRateStart(date);

    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    setRateEnd(nextDay);
  };

  const handleEndDateChange = (date) => {
    setRateEnd(date);
  };

  useEffect(() => {
    // The logic that runs when rateStart or rateEnd changes
    if (rateEnd <= rateStart) {
      setRateEnd(new Date(new Date().setDate(rateStart.getDate() + 1)));
    }
  }, [rateStart, rateEnd]);

  const isStartDateDisabled = (date) => {
    // Disable dates on or between rate start and end dates
    const rates = props.rates;
    if (rates && rates.length > 0) {
      return rates.some((rate) => {
        const rateStartDate = new Date(rate.rateStart);
        const rateEndDate = new Date(rate.rateEnd);
        return date >= rateStartDate && date <= rateEndDate;
      });
    }

    // By default, don't disable the date
    return false;
  };

  const isEndDateDisabled = (date) => {
    if (date < rateStart) return true;

    // Disable dates on or between rate start and end dates
    const rates = props.rates;
    if (rates && rates.length > 0) {
      return rates.some((rate) => {
        const rateStartDate = new Date(rate.rateStart);
        const rateEndDate = new Date(rate.rateEnd);
        return date >= rateStartDate && date <= rateEndDate;
      });
    }

    // By default, don't disable the date
    return false;
  };

  //
  // Edit Icon Stuff
  //
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState("");

  const [rateStartEdit, setRateStartEdit] = useState(new Date());
  const [rateEndEdit, setRateEndEdit] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [weeklyEdit, setWeeklyEdit] = useState(0);
  const [monthlyEdit, setMonthlyEdit] = useState(0);
  const [minStayEdit, setMinStayEdit] = useState(1);
  const [discount1Edit, setDiscount1Edit] = useState(0);
  const [discount2Edit, setDiscount2Edit] = useState(0);
  const [discount3Edit, setDiscount3Edit] = useState(0);
  const [discount4Edit, setDiscount4Edit] = useState(0);

  const handleStartDateChangeEdit = (date) => {
    setRateStartEdit(date);
  };

  const handleEndDateChangeEdit = (date) => {
    setRateEndEdit(date);
  };

  const handleWeeklyChangeEdit = (event) => {
    let { value } = event.target;

    // Remove existing commas and periods from the value
    value = value.replace(/,/g, "").replace(/\./g, "");

    // Use a regular expression to allow only numbers with up to two decimal places
    const regex = /^\d*$/;

    if (regex.test(value) || value === "") {
      setWeeklyEdit(value);
    }
  };

  const handleMonthlyChangeEdit = (event) => {
    let { value } = event.target;

    // Remove existing commas and periods from the value
    value = value.replace(/,/g, "").replace(/\./g, "");

    // Use a regular expression to allow only numbers with up to two decimal places
    let regex = /^\d*$/;

    if (regex.test(value) || value === "") {
      setMonthlyEdit(value);
    }
  };

  const handleMinStayChangeEdit = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setMinStayEdit(value);
    }
  };

  const handleDiscount1ChangeEdit = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setDiscount1Edit(value);
    }
  };

  const handleDiscount2ChangeEdit = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setDiscount2Edit(value);
    }
  };

  const handleDiscount3ChangeEdit = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setDiscount3Edit(value);
    }
  };

  const handleDiscount4ChangeEdit = (event) => {
    const { value } = event.target;

    // Use a regular expression to allow only numbers
    const regex = /^[0-9]*(?:\.[0-9]{0,2})?/;

    if (regex.test(value) || value === "") {
      setDiscount4Edit(value);
    }
  };

  const handleEditClick = (row) => {
    setRateStartEdit(row.rateStart ? new Date(row.rateStart) : new Date());
    setRateEndEdit(
      row.rateEnd
        ? new Date(row.rateEnd)
        : new Date(new Date().setDate(new Date().getDate() + 1))
    );
    setWeeklyEdit(row.weekly || 0);
    setMonthlyEdit(row.monthly || 0);
    setMinStayEdit(row.minStay || 1);
    setDiscount1Edit(row.discount1 || 0);
    setDiscount2Edit(row.discount2 || 0);
    setDiscount3Edit(row.discount3 || 0);
    setDiscount4Edit(row.discount4 || 0);

    if (row._id === editingId && isEditing) {
      setIsEditing(false);
      setEditingId(null);
    } else {
      setIsEditing(true);
      setEditingId(row._id);
    }
  };

  const handleSave = () => {
    const json = {
      rateId: editingId,
      updatedData: {
        rateStart: rateStartEdit,
        rateEnd: rateEndEdit,
        weekly: weeklyEdit,
        monthly: monthlyEdit,
        minStay: minStayEdit,
        discount1: discount1Edit,
        discount2: discount2Edit,
        discount3: discount3Edit,
        discount4: discount4Edit,
      },
    };

    const rates = props.rates.filter((rate) => rate._id !== editingId);

    // Check if the new rate conflicts with existing rates
    const isConflict = rates.some((rate) => {
      const existingRateStart = new Date(rate.rateStart);
      const existingRateEnd = new Date(rate.rateEnd);
      const newRateStart = new Date(rateStartEdit);
      const newRateEnd = new Date(rateEndEdit);

      // Check if the new rate overlaps with any existing rate
      return (
        (newRateStart >= existingRateStart &&
          newRateStart <= existingRateEnd) ||
        (newRateEnd >= existingRateStart && newRateEnd <= existingRateEnd) ||
        (existingRateStart >= newRateStart &&
          existingRateStart <= newRateEnd) ||
        (existingRateEnd >= newRateStart && existingRateEnd <= newRateEnd)
      );
    });

    // If there's a conflict, handle it (show a message, prevent submission, etc.)
    if (isConflict) {
      alert(
        "Error: This rate conflicts with existing rates. Please choose different dates."
      );
      // You might want to return or handle this case appropriately
      return;
    }

    editRate(json);
  };

  const isStartDateDisabledEdit = (date) => {
    const rates = props.rates.filter((rate) => rate._id !== editingId); // Exclude editing rate
    if (rates && rates.length > 0) {
      return rates.some((rate) => {
        const rateStartDate = new Date(rate.rateStart);
        const rateEndDate = new Date(rate.rateEnd);
        // To check if a date is disabled, it should fall within a rate's start and end date range, inclusive
        return date >= rateStartDate && date <= rateEndDate;
      });
    }
    return false;
  };

  const isEndDateDisabledEdit = (date) => {
    if (date < rateStartEdit) return true;

    const rates = props.rates.filter((rate) => rate._id !== editingId); // Exclude editing rate
    if (rates && rates.length > 0) {
      return rates.some((rate) => {
        const rateStartDate = new Date(rate.rateStart);
        const rateEndDate = new Date(rate.rateEnd);
        // To check if a date is disabled, it should fall within a rate's start and end date range, inclusive
        return date >= rateStartDate && date <= rateEndDate;
      });
    }
    return false;
  };

  //
  // Delete Icon Stuff
  //
  const [selectedRateToDelete, setSelectedRateToDelete] = useState([]);

  const [
    showDeleteSingleConfirmationModal,
    setShowDeleteSingleConfirmationModal,
  ] = useState(false);

  // When the user clicks the delete icon (trash can)
  const handleDeleteSingleClick = (id) => {
    setSelectedRateToDelete(id);
    setShowDeleteSingleConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleDeleteSingleCancel = () => {
    setShowDeleteSingleConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleDeleteSingleConfirm = () => {
    deleteRate(selectedRateToDelete);
    setShowDeleteSingleConfirmationModal(false);
  };

  //
  // Delete Selected
  //
  const [checkAll, setCheckAll] = useState(false);
  const [selectedRatesToDelete, setSelectedRatesToDelete] = useState([]);
  const [
    showDeleteSelectedConfirmationModal,
    setShowDeleteSelectedConfirmationModal,
  ] = useState(false);

  // When the top checkbox is clicked
  const handleCheckAll = (event) => {
    setCheckAll(event.target.checked);
    const checkboxes = document.querySelectorAll(
      'tbody input[type="checkbox"][name="ratesCheckbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
    });

    setSelectedRateToDelete(
      Array.from(
        document.querySelectorAll(
          'tbody input[type="checkbox"][name="ratesCheckbox"]:checked'
        )
      ).map((checkbox) => checkbox.id)
    );
  };

  // When a checkbox is clicked
  const handleCheckbox = (event) => {
    const checkboxes = document.querySelectorAll(
      'tbody input[type="checkbox"][name="ratesCheckbox"]'
    );
    const checkedCount = document.querySelectorAll(
      'tbody input[type="checkbox"][name="ratesCheckbox"]:checked'
    ).length;
    setCheckAll(checkedCount === checkboxes.length);

    setSelectedRatesToDelete(
      Array.from(
        document.querySelectorAll(
          'tbody input[type="checkbox"][name="ratesCheckbox"]:checked'
        )
      ).map((checkbox) => checkbox.id)
    );
  };

  // When the user clicks delete selected rates
  const handleDeleteSelectedClick = () => {
    setShowDeleteSelectedConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleDeleteSelectedCancel = () => {
    setShowDeleteSelectedConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleDeleteSelectedConfirm = () => {
    deleteRates(selectedRatesToDelete);
    setShowDeleteSelectedConfirmationModal(false);
  };

  function formatDate(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  // Rates Table
  const currentDate = new Date();
  const futureRates = props.rates.filter(
    (rate) => new Date(rate.rateEnd) >= currentDate
  );
  const pastRates = props.rates.filter(
    (rate) => new Date(rate.rateEnd) < currentDate
  );

  const futureRows = futureRates.map((row, index) => {
    return (
      <tr key={row._id} className={index % 2 === 0 ? "even-row" : "odd-row"}>
        <td>
          <input
            type="checkbox"
            name="ratesCheckbox"
            id={row._id}
            value={row._id}
            onChange={handleCheckbox}
          />
        </td>
        {isEditing && editingId === row._id ? (
          <>
            <td>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  onChange={handleStartDateChangeEdit}
                  value={rateStartEdit}
                  shouldDisableDate={(date) => isStartDateDisabledEdit(date)}
                />
              </LocalizationProvider>
            </td>
            <td>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  onChange={handleEndDateChangeEdit}
                  value={rateEndEdit}
                  shouldDisableDate={(date) => isEndDateDisabledEdit(date)}
                />
              </LocalizationProvider>
            </td>
            <td>
              <input
                type="text"
                id="weekly"
                value={weeklyEdit}
                onChange={handleWeeklyChangeEdit}
                className="custom-price-input-small"
                placeholder="Weekly Price"
              />
            </td>
            <td>
              <input
                type="text"
                id="monthly"
                value={monthlyEdit}
                onChange={handleMonthlyChangeEdit}
                className="custom-price-input-small"
                placeholder="Monthly Price"
              />
            </td>
            <td>
              <select
                id="minStay"
                value={minStayEdit}
                onChange={handleMinStayChangeEdit}
                className="custom-price-input-small"
              >
                {Array.from({ length: 100 }, (_, index) => index + 1).map(
                  (value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  )
                )}
              </select>
            </td>
            <td>
              {row.weekly
                ? (Number(weekly || row.weekly) / 7).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })
                : ""}
            </td>
            <td>
              <input
                type="text"
                id="discount1"
                value={discount1Edit}
                onChange={handleDiscount1ChangeEdit}
                className="custom-price-input-small"
                placeholder="7-13 Day Discount"
              />
            </td>
            <td>
              <input
                type="text"
                id="discount2"
                value={discount2Edit}
                onChange={handleDiscount2ChangeEdit}
                className="custom-price-input-small"
                placeholder="14-20 Day Discount"
              />
            </td>
            <td>
              <input
                type="text"
                id="discount3"
                value={discount3Edit}
                onChange={handleDiscount3ChangeEdit}
                className="custom-price-input-small"
                placeholder="21-27 Day Discount"
              />
            </td>
            <td>
              <input
                type="text"
                id="discount4"
                value={discount4Edit}
                onChange={handleDiscount4ChangeEdit}
                className="custom-price-input-small"
                placeholder="28-30 Day Discount"
              />
            </td>
          </>
        ) : (
          <>
            <td>{formatDate(row.rateStart)}</td>
            <td>{formatDate(row.rateEnd)}</td>
            <td>
              {row.weekly
                ? "$" +
                  Number(row.weekly).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })
                : ""}
            </td>
            <td>
              {row.monthly
                ? "$" +
                  Number(row.monthly).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })
                : ""}
            </td>
            <td>{row.minStay + (row.minStay > 1 ? " days" : " day")}</td>
            <td>
              {row.weekly
                ? "$" +
                  (Number(row.weekly) / 7).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })
                : ""}
            </td>
            <td>{row.discount1 ? row.discount1 + "%" : ""}</td>
            <td>{row.discount2 ? row.discount2 + "%" : ""}</td>
            <td>{row.discount3 ? row.discount3 + "%" : ""}</td>
            <td>{row.discount4 ? row.discount4 + "%" : ""}</td>
          </>
        )}

        <td>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleEditClick(row)}
          />
        </td>
        <td>
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteSingleClick(row._id)}
          />
        </td>
      </tr>
    );
  });

  const pastRows = pastRates.map((row, index) => {
    return (
      <tr key={row._id} className={index % 2 === 0 ? "even-row" : "odd-row"}>
        <td>
          <input
            type="checkbox"
            name="ratesCheckbox"
            id={row._id}
            value={row._id}
            onChange={handleCheckbox}
          />
        </td>
        {isEditing && editingId === row._id ? (
          <>
            <td>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  onChange={handleStartDateChangeEdit}
                  value={rateStartEdit}
                  shouldDisableDate={(date) => isStartDateDisabledEdit(date)}
                />
              </LocalizationProvider>
            </td>
            <td>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  onChange={handleEndDateChangeEdit}
                  value={rateEndEdit}
                  shouldDisableDate={(date) => isEndDateDisabledEdit(date)}
                />
              </LocalizationProvider>
            </td>
            <td>
              <input
                type="text"
                id="weekly"
                value={weeklyEdit}
                onChange={handleWeeklyChangeEdit}
                className="custom-price-input-small"
                placeholder="Weekly Price"
              />
            </td>
            <td>
              <input
                type="text"
                id="monthly"
                value={monthlyEdit}
                onChange={handleMonthlyChangeEdit}
                className="custom-price-input-small"
                placeholder="Monthly Price"
              />
            </td>
            <td>
              <select
                id="minStay"
                value={minStayEdit}
                onChange={handleMinStayChangeEdit}
                className="custom-price-input-small"
              >
                {Array.from({ length: 100 }, (_, index) => index + 1).map(
                  (value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  )
                )}
              </select>
            </td>
            <td>
              {row.weekly
                ? (Number(weekly || row.weekly) / 7).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })
                : ""}
            </td>
            <td>
              <input
                type="text"
                id="discount1"
                value={discount1Edit}
                onChange={handleDiscount1ChangeEdit}
                className="custom-price-input-small"
                placeholder="7-13 Day Discount"
              />
            </td>
            <td>
              <input
                type="text"
                id="discount2"
                value={discount2Edit}
                onChange={handleDiscount2ChangeEdit}
                className="custom-price-input-small"
                placeholder="14-20 Day Discount"
              />
            </td>
            <td>
              <input
                type="text"
                id="discount3"
                value={discount3Edit}
                onChange={handleDiscount3ChangeEdit}
                className="custom-price-input-small"
                placeholder="21-27 Day Discount"
              />
            </td>
            <td>
              <input
                type="text"
                id="discount4"
                value={discount4Edit}
                onChange={handleDiscount4ChangeEdit}
                className="custom-price-input-small"
                placeholder="28-30 Day Discount"
              />
            </td>
          </>
        ) : (
          <>
            <td>{formatDate(row.rateStart)}</td>
            <td>{formatDate(row.rateEnd)}</td>
            <td>
              {row.weekly
                ? "$" +
                  Number(row.weekly).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })
                : ""}
            </td>
            <td>
              {row.monthly
                ? "$" +
                  Number(row.monthly).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })
                : ""}
            </td>
            <td>{row.minStay + (row.minStay > 1 ? " days" : " day")}</td>
            <td>
              {row.weekly
                ? "$" +
                  (Number(row.weekly) / 7).toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })
                : ""}
            </td>
            <td>{row.discount1 ? row.discount1 + "%" : ""}</td>
            <td>{row.discount2 ? row.discount2 + "%" : ""}</td>
            <td>{row.discount3 ? row.discount3 + "%" : ""}</td>
            <td>{row.discount4 ? row.discount4 + "%" : ""}</td>
          </>
        )}

        <td>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleEditClick(row)}
          />
        </td>
        <td>
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteSingleClick(row._id)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div>
      {isEditing ? (
        <>
          <SaveButton onClick={handleSave} />
        </>
      ) : (
        <></>
      )}
      <div style={{ width: "95%", margin: "0 auto" }}>
        <div style={{ overflowX: "auto", marginBottom: "5%", marginTop: "3%" }}>
          <table className="admin-table">
            <thead>
              <tr>
                <th
                  className="header-cell"
                  style={{ background: "lightgreen" }}
                >
                  Fees:
                </th>
                <th className="header-cell">Amount</th>
                <th className="header-cell">How Charged</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Default Minimum Nights</td>
                <td>
                  <input
                    type="text"
                    id="minNights"
                    value={minNights}
                    onChange={handleMinNightsChange}
                    className="custom-price-input"
                    placeholder="Default Minimum Nights"
                  />
                </td>
                <td style={{ textAlign: "left" }}>days</td>
              </tr>
              <tr>
                <td>Default Weekly Rate</td>
                <td>
                  $
                  <input
                    type="text"
                    id="baseWeekly"
                    value={baseWeekly}
                    onChange={handleBaseWeeklyChange}
                    className="custom-price-input"
                    placeholder="Default Weekly Rate"
                  />
                </td>
                <td style={{ textAlign: "left" }}>per week</td>
              </tr>
              <tr>
                <td>Pet Fee</td>
                <td>
                  $
                  <input
                    type="text"
                    id="petFee"
                    value={petFee}
                    onChange={handlePetFeeChange}
                    className="custom-price-input"
                    placeholder="Pet Fee"
                  />
                </td>
                <td style={{ textAlign: "left" }}>per stay</td>
              </tr>
              <tr>
                <td>Pool Heater</td>
                <td>
                  $
                  <input
                    type="text"
                    id="poolHeater"
                    value={poolHeater}
                    onChange={handlePoolHeaterChange}
                    className="custom-price-input"
                    placeholder="Pool Heater"
                  />
                </td>
                <td style={{ textAlign: "left" }}>per week</td>
              </tr>
              <tr>
                <td>Lodging Tax</td>
                <td>
                  <input
                    type="text"
                    id="lodgingTax"
                    value={lodgingTax}
                    onChange={handleLodgingTaxChange}
                    className="custom-price-input"
                    placeholder="Lodging Tax"
                  />
                  %
                </td>
                <td style={{ textAlign: "left" }}>
                  applies to total of rate and fees (not including booking fees)
                </td>
              </tr>
              <tr>
                <td>Booking Fee</td>
                <td>
                  <input
                    type="text"
                    id="bookingFee"
                    value={bookingFee}
                    onChange={handleBookingFee}
                    className="custom-price-input"
                    placeholder="Booking Fee"
                  />
                  %
                </td>
                <td style={{ textAlign: "left" }}>
                  applies to total of rate and fees (not including tax)
                </td>
              </tr>
              <tr>
                <td>Cleaning Fee</td>
                <td>
                  $
                  <input
                    type="text"
                    id="cleaningFee"
                    value={cleaningFee}
                    onChange={handleCleaningFee}
                    className="custom-price-input"
                    placeholder="Cleaning Fee"
                  />
                </td>
                <td style={{ textAlign: "left" }}>per stay</td>
              </tr>
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5px",
            }}
          >
            <Button variant="contained" color="primary" onClick={saveFeesClick}>
              Save
            </Button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginBottom: "25px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddRateClick}
          >
            Add Rate
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteSelectedClick}
            disabled={selectedRatesToDelete.length === 0}
          >
            Delete Selected Rates
          </Button>
        </div>
        <div style={{ overflowX: "auto", marginBottom: "5%" }}>
          <table className="admin-table">
            <thead>
              <tr>
                <th style={{ visibility: "hidden", border: "none" }}></th>
                <th
                  className="header-cell"
                  style={{ background: "lightgreen" }}
                >
                  Rates:
                </th>
                <th
                  style={{ visibility: "hidden", border: "none" }}
                  colSpan={5}
                ></th>
                <th
                  className="header-cell"
                  colSpan={4}
                  style={{ background: "lightgreen" }}
                >
                  Discounts to apply for length of stay
                </th>
              </tr>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={checkAll}
                    onChange={handleCheckAll}
                    id="checkAll"
                  />
                </th>
                <th className="header-cell">Rate Start:</th>
                <th className="header-cell">Rate End:</th>
                <th className="header-cell">Weekly:</th>
                <th className="header-cell">Monthly:</th>
                <th className="header-cell">Min Stay:</th>
                <th className="header-cell">Daily (weekly/7):</th>
                <th className="header-cell">7-13:</th>
                <th className="header-cell">14-20:</th>
                <th className="header-cell">21-27:</th>
                <th className="header-cell">28-30:</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>{futureRows}</tbody>
          </table>
        </div>
      </div>

      {pastRates.length > 0 && (
        <div>
          <h2
            style={{
              margin: "0 auto",
              textAlign: "center",
              marginBottom: "15px",
              marginTop: "50px",
            }}
          >
            Previous Rates
          </h2>
          <div style={{ overflowX: "auto", marginBottom: "5%" }}>
            <table className="admin-table">
              <thead>
                <tr>
                  <th style={{ visibility: "hidden", border: "none" }}></th>
                  <th
                    className="header-cell"
                    style={{ background: "lightgreen" }}
                  >
                    Rates:
                  </th>
                  <th
                    style={{ visibility: "hidden", border: "none" }}
                    colSpan={5}
                  ></th>
                  <th
                    className="header-cell"
                    colSpan={4}
                    style={{ background: "lightgreen" }}
                  >
                    Discounts to apply for length of stay
                  </th>
                </tr>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={checkAll}
                      onChange={handleCheckAll}
                      id="checkAll"
                    />
                  </th>
                  <th className="header-cell">Rate Start:</th>
                  <th className="header-cell">Rate End:</th>
                  <th className="header-cell">Weekly:</th>
                  <th className="header-cell">Monthly:</th>
                  <th className="header-cell">Min Stay:</th>
                  <th className="header-cell">Daily (weekly/7):</th>
                  <th className="header-cell">7-13:</th>
                  <th className="header-cell">14-20:</th>
                  <th className="header-cell">21-27:</th>
                  <th className="header-cell">28-30:</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* Display past rates */}
                {pastRows}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Dialog
        open={showDeleteSingleConfirmationModal}
        onClose={handleDeleteSingleCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the selected rate?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSingleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSingleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteSelectedConfirmationModal}
        onClose={handleDeleteSelectedCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the selected rates?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSelectedCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSelectedConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAddRateModal} onClose={handleAddRateCancel}>
        <DialogTitle>Add Rate</DialogTitle>
        <DialogContent>
          <div style={{ margin: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Start Date"
                onChange={handleStartDateChange}
                value={rateStart}
                shouldDisableDate={(date) => isStartDateDisabled(date)}
              />
            </LocalizationProvider>
          </div>

          <div style={{ margin: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="End Date"
                onChange={handleEndDateChange}
                value={rateEnd}
                shouldDisableDate={(date) => isEndDateDisabled(date)}
              />
            </LocalizationProvider>
          </div>

          <div className="input-container">
            <label htmlFor="weekly" className="custom-price-label">
              Weekly Price: $
            </label>
            <input
              type="text"
              id="weekly"
              value={weekly}
              onChange={handleWeeklyChange}
              className="custom-price-input"
              placeholder="Weekly Price"
            />
          </div>

          <div className="input-container">
            <label htmlFor="monthly" className="custom-price-label">
              Monthly Price: $
            </label>
            <input
              type="text"
              id="monthly"
              value={monthly}
              onChange={handleMonthlyChange}
              className="custom-price-input"
              placeholder="Monthly Price"
            />
          </div>

          <div className="input-container">
            <label htmlFor="minStay" className="custom-price-label">
              Minimum Stay:
            </label>
            <select
              id="minStay"
              value={minStay}
              onChange={handleMinStayChange}
              className="custom-price-input"
            >
              {Array.from({ length: 100 }, (_, index) => index + 1).map(
                (value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                )
              )}
            </select>
          </div>

          <div className="input-container">
            <label htmlFor="discount1" className="custom-price-label">
              7-13 Day Discount: %
            </label>
            <input
              type="text"
              id="discount1"
              value={discount1}
              onChange={handleDiscount1Change}
              className="custom-price-input"
              placeholder="7-13 Day Discount"
            />
          </div>

          <div className="input-container">
            <label htmlFor="discount2" className="custom-price-label">
              14-20 Day Discount: %
            </label>
            <input
              type="text"
              id="discount2"
              value={discount2}
              onChange={handleDiscount2Change}
              className="custom-price-input"
              placeholder="14-20 Day Discount"
            />
          </div>

          <div className="input-container">
            <label htmlFor="discount3" className="custom-price-label">
              21-27 Day Discount: %
            </label>
            <input
              type="text"
              id="discount3"
              value={discount3}
              onChange={handleDiscount3Change}
              className="custom-price-input"
              placeholder="21-27 Day Discount"
            />
          </div>

          <div className="input-container">
            <label htmlFor="discount4" className="custom-price-label">
              28-30 Day Discount: %
            </label>
            <input
              type="text"
              id="discount4"
              value={discount4}
              onChange={handleDiscount4Change}
              className="custom-price-input"
              placeholder="28-30 Day Discount"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddRateCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddRateConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Rates;
