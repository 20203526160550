import React, { useState } from "react";
import "./styles.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";

function Slideshow(props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = props.slideshowImages;

  const handlePrevClick = (event) => {
    event.stopPropagation();
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleNextClick = (event) => {
    event.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleImageClick = (event) => {
    const x = event.nativeEvent.offsetX;
    const width = event.target.clientWidth;

    if (x < width / 2) {
      handlePrevClick(event);
    } else {
      handleNextClick(event);
    }
  };

  return (
    <div className="slideshow" onClick={(event) => handleImageClick(event)}>
      <img
        src={images[currentIndex].path}
        alt={`${currentIndex}`}
        style={{ position: "relative" }}
      />

      <i className="arrow left" onClick={(event) => handlePrevClick(event)}>
        &lt;
      </i>
      <i className="arrow right" onClick={(event) => handleNextClick(event)}>
        &gt;
      </i>
    </div>
  );
}

export default Slideshow;
