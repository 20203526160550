import { useState, useEffect } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function useHome() {
  const [home, setHome] = useState();

  useEffect(() => {
    const getHome = async () => {
      try {
        const response = await fetch(apiUrl + `/home/`);
        const json = await response.json();

        if (!response.ok) {
          return;
        }

        const [firstHome] = json; // get the first object in the array
        setHome(firstHome);
      } catch (error) {
        return;
      }
    };

    getHome();
  }, []);

  return home;
}

export default useHome;
