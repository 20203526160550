import React, { useState } from "react";
import "./overview-styles.css";
import Tooltip from "@mui/material/Tooltip";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

const images = [
  apiUrl + "/images/icons/bed.png",
  apiUrl + "/images/icons/bath.png",
  apiUrl + "/images/icons/family.png",
  apiUrl + "/images/icons/moon.png",
];

const OverviewCounts = (props) => {
  const [showTooltip, setShowToolTip] = useState(false);

  const nightsMinDiv =
    props.overviewCounts.minNights === "Minimum stay varies" ? (
      <>
        <Tooltip
          title={
            <span className="tooltip-content-overview">
              The minimum stay for this property changes depending on the dates
              selected
            </span>
          }
          arrow
          open={showTooltip}
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={() => setShowToolTip(!showTooltip)}
            onMouseLeave={() => setShowToolTip(!showTooltip)}
          >
            <span>{props.overviewCounts.minNights}</span>&nbsp;
            <img
              src={apiUrl + "/images/icons/info.png"}
              alt="info"
              height="20px"
            />
          </div>
        </Tooltip>
      </>
    ) : (
      <>{props.overviewCounts.minNights} nights minimum</>
    );

  return (
    <div className="overview-container">
      <div className="item-overview">
        <img src={images[0]} alt="bedrooms" height="50%" />
        <span className="item-text">
          {props.overviewCounts.bedrooms} bedrooms
        </span>
      </div>
      <div className="item-overview">
        <img src={images[1]} alt="bathrooms" height="50%" />
        <span className="item-text">
          {props.overviewCounts.bathrooms} bathrooms
        </span>
      </div>
      <div className="item-overview">
        <img src={images[2]} alt="guests" height="50%" />
        <span className="item-text">{props.overviewCounts.guests} guests</span>
      </div>
      <div className="item-overview">
        <img src={images[3]} alt="nights" height="50%" />
        <span className="item-text">{nightsMinDiv}</span>
      </div>
    </div>
  );
};

export default OverviewCounts;
