import { useState, useEffect } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function useDatesUnavailable() {
  const [datesUnavailable, setDatesUnavailable] = useState([]);

  useEffect(() => {
    const fetchDatesUnavailable = async () => {
      try {
        const response = await fetch(apiUrl + `/home/datesUnavailable`);
        if (!response.ok) {
          throw new Error(`An error occurred: ${response.statusText}`);
        }
        const json = await response.json();

        if (json === null) return null;

        const firstDateUnavailable = json.datesUnavailable; // Assuming the response is an array with a single object containing datesUnavailable
        if (firstDateUnavailable) {
          setDatesUnavailable(firstDateUnavailable || []);
        }
      } catch (error) {
        console.error(error);
        // Handle the error as needed, e.g., display an error message to the user.
      }
    };

    fetchDatesUnavailable();
  }, []);

  return datesUnavailable;
}

export default useDatesUnavailable;
