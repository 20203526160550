import React, { useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import {
  deleteImageFromSlideshow,
  saveSlideshowOrder,
} from "../Admin/House/SubmitHandler";
import "./ImageList.css";
import { StrictModeDroppable as Droppable } from "./Droppable";

const ImageList = ({ images }) => {
  const [items, setItems] = useState(images);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    setItems(newItems);
  };

  const handleDeleteClick = (id, index) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      deleteImageFromSlideshow(items, id, index);
      setItems(items.filter((item) => item._id !== id));
    }
  };

  const handleSaveOrder = async () => {
    const imageIds = items.map((item) => item._id);
    try {
      const status = await saveSlideshowOrder(imageIds);
      if (status === "saved") {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error saving order:", error);
      // Handle error
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <button
          style={{
            marginBottom: "15px",
            marginTop: "15px",
            fontSize: "22px",
            fontWeight: "bold",
          }}
          onClick={handleSaveOrder}
        >
          Save Order
        </button>
        <Droppable droppableId="images">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="image-container"
                    >
                      <img
                        src={item.path}
                        alt={index}
                        style={{ cursor: "pointer" }}
                        title={"Drag to re-order"}
                        onClick={() => handleDeleteClick(item._id, index)}
                      />
                      <div
                        className="delete-button"
                        onClick={() => handleDeleteClick(item._id, index)}
                      >
                        X
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default ImageList;
