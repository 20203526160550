import Axios from "axios";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

export const createJson = (
  title,
  bedrooms,
  bathrooms,
  guests,
  minNights,
  overviewType,
  overview,
  pets,
  smoking,
  kids,
  overviewBathrooms,
  overviewBedrooms,
  displayName,
  note,
  languagesSpoken,
  responseTime,
  listedSince,
  interaction,
  amenities,
  reviews,
  googleMapsSRC,
  aboutTheNeighborhood,
  gettingAround,
  access,
  howToGetThere,
  activitiesNearby,
  cancellationPolicy,
  noteFromOwner,
  unavailableDates,
  reviewOwnersNote
) => {
  var json_data = {
    title: title ? title : "",
    overviewCounts: {
      bedrooms: bedrooms ? bedrooms : "",
      bathrooms: bathrooms ? bathrooms : "",
      guests: guests ? guests : "",
      minNights: minNights ? minNights : "",
    },
    overviewType: overviewType ? overviewType : "",
    overview: overview ? overview : "",
    overviewRules: {
      petsAllowed: pets ? pets : "",
      smokingAllowed: smoking ? smoking : "",
      kidFriendly: kids ? kids : "",
    },
    overviewBathrooms: overviewBathrooms ? overviewBathrooms : "",
    overviewBedrooms: overviewBedrooms ? overviewBedrooms : "",
    owner: {
      displayName: displayName ? displayName : "",
      note: note ? note : "",
      languagesSpoken: languagesSpoken ? languagesSpoken : "",
      responseTime: responseTime ? responseTime : "",
      listedSince: listedSince ? listedSince : "",
      interaction: interaction ? interaction : "",
    },
    reviews: reviews ? reviews : "",
    amenities: amenities ? amenities : "",
    map: {
      googleMapsSRC: googleMapsSRC ? googleMapsSRC : "",
      aboutTheNeighborhood: aboutTheNeighborhood ? aboutTheNeighborhood : "",
      gettingAround: gettingAround ? gettingAround : "",
      access: access ? access : "",
      howToGetThere: howToGetThere ? howToGetThere : "",
      activitiesNearby: activitiesNearby ? activitiesNearby : "",
    },
    cancellationPolicy: cancellationPolicy ? cancellationPolicy : "",
    noteFromOwner: noteFromOwner ? noteFromOwner : "",
    unavailableDates: unavailableDates ? unavailableDates : "",
    reviewOwnersNote: reviewOwnersNote ? reviewOwnersNote : "",
  };

  return json_data;
};

export const saveHome = (json) => {
  const token = localStorage.getItem("token");
  Axios.post(apiUrl + "/home/add", json, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      console.error("Error saving home:", error);
    });
};

function formatDate(date) {
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month (0-based) is incremented by 1
  const day = String(date.getDate()).padStart(2, "0"); // Day of the month
  const year = date.getFullYear(); // Four-digit year

  return `${month}/${day}/${year}`;
}

export const saveDateUnavailable = (date) => {
  date = formatDate(date);
  const token = localStorage.getItem("token");
  Axios.post(
    apiUrl + "/home/addDateUnavailable",
    { date: date },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then(() => {
      window.location.reload();
    })
    .catch(() => console.error("Error saving date unavailable."));
};

export const saveDatesUnavailable = (startDate, endDate, callback) => {
  const token = localStorage.getItem("token");
  startDate = formatDate(startDate);
  endDate = formatDate(endDate);
  Axios.post(
    apiUrl + "/home/addDatesUnavailable",
    {
      startDate: startDate,
      endDate: endDate,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then(() => {
      if (callback) callback();
      else window.location.reload();
    })
    .catch(() => console.error("Error saving dates unavailable."));
};

export const removeDateUnavailable = (unavailableDateToRemove, callback) => {
  const token = localStorage.getItem("token");
  fetch(apiUrl + "/home/removeDateUnavailable", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ date: unavailableDateToRemove }),
  })
    .then(() => {
      // You can perform additional actions after a successful removal if needed
      if (callback) callback();
      else window.location.reload();
    })
    .catch((error) => {
      console.error("Error removing date: ", error);
      // Handle errors or provide user feedback
    });
};

export const removeDatesUnavailable = (datesToRemove, callback) => {
  const token = localStorage.getItem("token");
  fetch(apiUrl + "/home/removeDatesUnavailable", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ dates: datesToRemove }),
  })
    .then(() => {
      // You can perform additional actions after a successful removal if needed
      if (callback) callback();
      else window.location.reload();
    })
    .catch((error) => {
      console.error("Error removing dates: ", error);
      // Handle errors or provide user feedback
    });
};

export const addToSlideshow = (files) => {
  const token = localStorage.getItem("token");
  if (!files) return;

  const formData = new FormData();
  Array.from(files).forEach((file) => {
    formData.append("files", file);
  });

  fetch(apiUrl + "/slideshow/addImage", {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(() => {
      window.location.reload();
    })
    .catch(() => console.error("Error saving image."));
};

export const deleteImageFromSlideshow = (imageList, id, index) => {
  const token = localStorage.getItem("token");
  const imageUrl = id;

  // Delete image from server
  Axios.delete(apiUrl + `/slideshow/${imageUrl}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      return;
    });

  imageList.splice(index, 1);
  return imageList;
};

export const saveSlideshowOrder = (updatedOrder) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");

    Axios.post(
      apiUrl + "/slideshow/reorder",
      { images: updatedOrder },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        resolve("saved");
        // Optionally, you can handle any further actions upon successful order save
      })
      .catch((error) => {
        reject(error);
        // Handle any error that occurs during order save
      });
  });
};

export const replaceOwnerImage = (input, imageUrl) => {
  const token = localStorage.getItem("token");
  if (input.files.length === 0) return;

  const file = input.files[0];
  const reader = new FileReader();
  reader.onloadend = () => {
    const formData = new FormData();
    formData.append("file", file);

    fetch(apiUrl + "/owner/addImage", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        if (imageUrl === null || imageUrl.length === 0) {
          window.location.reload();
          return;
        }

        // Delete image from server
        const imageNameToDelete = String(imageUrl).split("/").pop();
        Axios.delete(apiUrl + `/owner/${imageNameToDelete}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(() => {
            window.location.reload();
          })
          .catch((error) => {
            return;
          });
      })
      .catch((error) => console.error("Error saving image: " + error));
  };
  reader.readAsDataURL(file);
};

export const deleteInquiry = (id) => {
  const token = localStorage.getItem("token");
  Axios.delete(apiUrl + `/inquiries/delete?id=${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(() => {
      window.location.reload();
    })
    .catch();
};

export const deleteInquiries = (ids) => {
  const token = localStorage.getItem("token");
  Axios.delete(apiUrl + `/inquiries/delete-multiple`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: { ids: ids },
  })
    .then(() => {
      window.location.reload();
    })
    .catch();
};
