import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { confirmReview, deleteReview } from "../Reviews/SubmitHandler.js";
import "../admin.css";

function Unconfirmed(props) {
  //
  // Delete Icon
  //
  const [selectedReviewToDelete, setSelectedReviewToDelete] = useState([]);
  const [selectedReviewToDeleteID, setSelectedReviewToDeleteID] = useState([]);
  const [
    showDeleteReviewConfirmationModal,
    setShowDeleteReviewConfirmationModal,
  ] = useState(false);

  // When the user clicks the delete icon (trash can)
  const handleDeleteReviewClick = (email, id) => {
    setSelectedReviewToDelete(email);
    setSelectedReviewToDeleteID(id);
    setShowDeleteReviewConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleDeleteReviewCancel = () => {
    setShowDeleteReviewConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleDeleteReviewConfirm = () => {
    // in submit handler the call for deleting an unconfirmed review from the db
    deleteReview(selectedReviewToDeleteID);
    setShowDeleteReviewConfirmationModal(false);
  };

  //
  // Confirm Icon
  //
  const [selectedReviewToConfirm, setSelectedReviewToConfirm] = useState([]);
  const [selectedReviewToConfirmID, setSelectedReviewToConfirmID] = useState(
    []
  );
  const [
    showConfirmReviewConfirmationModal,
    setShowConfirmReviewConfirmationModal,
  ] = useState(false);

  // When the user clicks the Confirm icon (trash can)
  const handleConfirmReviewClick = (email, id) => {
    setSelectedReviewToConfirm(email);
    setSelectedReviewToConfirmID(id);
    setShowConfirmReviewConfirmationModal(true);
  };

  // When the user clicks cancel in the modal
  const handleConfirmReviewCancel = () => {
    setShowConfirmReviewConfirmationModal(false);
  };

  // When the user clicks confirm in the modal
  const handleConfirmReviewConfirm = () => {
    confirmReview(selectedReviewToConfirmID);
    setShowConfirmReviewConfirmationModal(false);
  };

  const rows = props.unconfirmedReviews.map((data, index) => {
    return (
      <tr key={index}>
        <td>{data.email}</td>
        <td>{data.rating}</td>
        <td>{data.dateOfStay}</td>
        <td>{data.reviewTitle}</td>
        <td>{data.reviewBody}</td>
        <td>
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteReviewClick(data.email, data._id)}
          />
        </td>
        <td>
          <CheckIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleConfirmReviewClick(data.email, data._id)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="header-large">Pending Reviews:</div>
      <table className="review-table">
        <thead>
          <tr>
            <th>Email:</th>
            <th>Rating:</th>
            <th>Date of Stay:</th>
            <th>Review Title:</th>
            <th>Review Body:</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      <Dialog
        open={showDeleteReviewConfirmationModal}
        onClose={handleDeleteReviewCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the review left by "
          {selectedReviewToDelete}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteReviewCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteReviewConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showConfirmReviewConfirmationModal}
        onClose={handleConfirmReviewCancel}
      >
        <DialogTitle>Confirm Review</DialogTitle>
        <DialogContent>
          Are you sure you want to confirm the review left by "
          {selectedReviewToConfirm}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmReviewCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmReviewConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Unconfirmed;
