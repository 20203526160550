import React from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

const fullStar = apiUrl + "/images/icons/star_full.png";
const emptyStar = apiUrl + "/images/icons/star_empty.png";

const StarRating = (props) => {
  return (
    <div>
      {[1, 2, 3, 4, 5].map((starNumber) => (
        <img
          key={starNumber}
          src={starNumber <= props.rating ? fullStar : emptyStar}
          alt={`Star ${starNumber}`}
          style={{
            width: "24px",
            height: "24px",
          }}
        />
      ))}
    </div>
  );
};

export default StarRating;
