import { useState, useEffect } from "react";

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.siestatropicalretreat.com";

function useSlideshowImages() {
  const [images, setImages] = useState();

  useEffect(() => {
    const getSlideshowImages = async () => {
      try {
        const response = await fetch(apiUrl + `/slideshow/`);

        const json = await response.json();

        if (!response.ok) {
          setImages([]);
          return;
        }

        setImages(json);
      } catch (error) {
        const message = `An error occurred: ${error}`;
        window.alert(message);
        return;
      }
    };

    getSlideshowImages();
  }, []);

  return images;
}

export default useSlideshowImages;
