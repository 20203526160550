import React from "react";
import DatesUnavailable from "./DatesUnavailable";

function Bookings(props) {
  return (
    <div>
      <DatesUnavailable datesUnavailable={props.datesUnavailable} />
    </div>
  );
}

export default Bookings;
